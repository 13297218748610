// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeaturesStatus_status__js2rE {
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
  padding: 4px 8px;
  border-radius: 16px;
  background: #40187a;
}
.FeaturesStatus_status__js2rE div {
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}
.FeaturesStatus_status__soon__NxbCa {
  border: 1px solid #c7ffa8;
}
.FeaturesStatus_status__soon__NxbCa div {
  color: #c7ffa8;
}
.FeaturesStatus_status__progress__8svT1 {
  border: 1px solid #8fc3ff;
}
.FeaturesStatus_status__progress__8svT1 div {
  color: #8fc3ff;
}
.FeaturesStatus_status__backlog__o-6JA {
  border: 1px solid #feca9f;
}
.FeaturesStatus_status__backlog__o-6JA div {
  color: #feca9f;
}`, "",{"version":3,"sources":["webpack://./src/pages/Landing/components/FeaturesStatus/FeaturesStatus.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;AAAE;EACE,yBAAA;AAEJ;AADI;EACE,cAAA;AAGN;AAAE;EACE,yBAAA;AAEJ;AADI;EACE,cAAA;AAGN;AAAE;EACE,yBAAA;AAEJ;AADI;EACE,cAAA;AAGN","sourcesContent":[".status {\n  display: inline-flex;\n  align-items: center;\n  column-gap: 4px;\n  padding: 4px 8px;\n  border-radius: 16px;\n  background: #40187a;\n  div {\n    font-size: 12px;\n    font-weight: 600;\n    white-space: nowrap;\n  }\n  &__soon {\n    border: 1px solid #c7ffa8;\n    div {\n      color: #c7ffa8;\n    }\n  }\n  &__progress {\n    border: 1px solid #8fc3ff;\n    div {\n      color: #8fc3ff;\n    }\n  }\n  &__backlog {\n    border: 1px solid #feca9f;\n    div {\n      color: #feca9f;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `FeaturesStatus_status__js2rE`,
	"status__soon": `FeaturesStatus_status__soon__NxbCa`,
	"status__progress": `FeaturesStatus_status__progress__8svT1`,
	"status__backlog": `FeaturesStatus_status__backlog__o-6JA`
};
export default ___CSS_LOADER_EXPORT___;
