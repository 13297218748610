// src/ApolloProvider.jsx
import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider as Provider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI || 'https://graphql.heroguys.io/graphql',
});

const authLink = setContext((_, { headers }) => {
    // Get the authentication token from cookies if it exists
    const token = Cookies.get('token');
    // Return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

const ApolloProvider = ({ children }) => (
    <Provider client={client}>
        {children}
    </Provider>
);

export default ApolloProvider;