import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./TaskActionMessage.module.scss";
import { Clipboard, Check } from 'lucide-react'; // Import the icons from lucide-react
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import JSON5 from 'json5';
import { createAppKit } from '@reown/appkit/react';
import { WagmiProvider } from 'wagmi';
import { arbitrum, mainnet } from '@reown/appkit/networks';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { WagmiHooks } from './hooks/hooks'
import { useQuery } from "@apollo/client";
import { useAccount } from 'wagmi';



import questTwitterIcon from "../../../../img/twitter.svg";

import ChampionIcon from "../../../../img/champion.svg";

import Champion1Icon from "../../../../img/champion1.svg";
import Champion2Icon from "../../../../img/champion2.svg";
import Champion3Icon from "../../../../img/champion3.svg";

const SUBSCRIBE_MUTATION = gql`
mutation Subscribe($subscribeInput: SubscribeInput!) {
    subscribe(subscribeInput: $subscribeInput) {
      id
      email
      isConfirmed
      confirmationCode
      user {
        id
        name
      }
      campaign {
        id
        name
      }
      task {
        id
        title
      }
    }
  }
`;

const SUBMIT_FORM_FIELD_MUTATION = gql`
  mutation SubmitFormField($submitFormFieldInput: SubmitFormFieldInput!) {
    submitFormField(submitFormFieldInput: $submitFormFieldInput) {
      id
      user {
        id
        email
      }
      task {
        id
        title
      }
      fieldId
      fieldName
      fieldValue
      submittedAt
    }
  }
`;

const GET_REWARD_CATEGORIES = gql`
  query GetRewardCategories {
    rewardCategories {
      id
      name
      rewards {
        id
        name
        description
        metadata
        createdAt
        updatedAt
        fileUrl
      }
      createdAt
      updatedAt
    }
  }
`;

// 0. Setup QueryClient
const queryClient = new QueryClient();

// 1. Get projectId from https://cloud.reown.com
const projectId = '608542f5810fa0be3f80bcfe2c193c02'; // Replace with your actual project ID

// 2. Create a metadata object - optional
const metadata = {
    name: 'Hero Guys',
    description: 'Hero Guys Game',
    url: 'https://localhost:3002', // Origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/179229932'],
};

// 3. Set the networks
const networks = [mainnet, arbitrum];

// 4. Create Wagmi Adapter
const wagmiAdapter = new WagmiAdapter({
    networks,
    projectId,
    ssr: true,
});



const modal = createAppKit({
    adapters: [wagmiAdapter],
    networks,
    projectId,
    metadata,
    features: {
        analytics: true,
    },
});

// Create AppKitProvider component
function AppKitProvider({ children }) {
    return (
        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </WagmiProvider>
    );
}

const TaskActionMessage = ({ task, scrollToTop, handleVerify, quest }) => {
    // Function to get specific content based on task type


    console.log("TaskActionMessagge - task:\n", task);
    const { type, description } = task;
    const [email, setEmail] = useState('');
    const [formFieldValue, setFormFieldValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [formError, setFormError] = useState('');
    const [copied, setCopied] = useState(false);
    const [subscribeMutation] = useMutation(SUBSCRIBE_MUTATION);

    // Add new state for quiz handling
    const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
    const [quizAnswers, setQuizAnswers] = useState({});
    const [quizError, setQuizError] = useState('');

    const { data: rewardCategoriesData, loading: rewardCategoriesLoading } = useQuery(GET_REWARD_CATEGORIES);
    console.log("rewardCategoriesData:\n", rewardCategoriesData?.rewardCategories);

    // Clear form errors when task changes
    useEffect(() => {
        setFormError('');
        setFormFieldValue('');
        setCurrentQuizIndex(0);
        setQuizAnswers({});
        setQuizError('');
    }, [task.id]);


    // Add this function to handle quiz submission
    const handleQuizSubmission = async () => {
        const currentQuestion = JSON5.parse(task.extraData["Quiz code"])[currentQuizIndex];
        const currentAnswer = formFieldValue;

        // Validate answer
        if (!currentAnswer && currentQuestion.required) {
            setFormError('This question requires an answer');
            return;
        }

        // Special validation for checkbox type (array comparison)
        if (currentQuestion.type === 'checkbox' && currentQuestion.answer) {
            const isCorrect = JSON.stringify(currentAnswer.sort()) === JSON.stringify(currentQuestion.answer.sort());
            if (!isCorrect) {
                setFormError('Incorrect answer. Please try again.');
                return;
            }
        }
        // Validation for other types with answers
        else if (currentQuestion.answer) {
            const isCorrect = String(currentAnswer).toLowerCase() === String(currentQuestion.answer).toLowerCase();
            if (!isCorrect) {
                setFormError('Incorrect answer. Please try again.');
                return;
            }
        }

        // Store answer
        setQuizAnswers(prev => ({
            ...prev,
            [currentQuestion.name]: currentAnswer
        }));

        // Clear form value and error
        setFormFieldValue('');
        setFormError('');

        const questions = JSON5.parse(task.extraData["Quiz code"]);
        if (currentQuizIndex < questions.length - 1) {
            // Move to next question
            setCurrentQuizIndex(prev => prev + 1);
        } else {
            // Submit all answers
            try {
                await submitFormField({
                    variables: {
                        submitFormFieldInput: {
                            taskId: task.id,
                            fieldId: task.id,
                            fieldName: 'quiz_answers',
                            fieldValue: JSON.stringify(quizAnswers),
                        },
                    },
                });

                if (task.onAction) {
                    task.onAction();
                } else {
                    handleVerify();
                }
            } catch (error) {
                setQuizError(error.message);
            }
        }
    };

    // Initialize the mutation
    const [submitFormField, { loading }] = useMutation(SUBMIT_FORM_FIELD_MUTATION, {
        onCompleted: (data) => {
            console.log('Form submitted successfully:', data);
            // Optional: Reset input fields or perform other actions
            setFormFieldValue('');
        },
        onError: (error) => {
            console.error('Error submitting form:', error);
            setErrorMessage(error.message);
        },
    });

    // Function to handle form field submission
    const handleFormFieldSubmission = async () => {
        // Check if the field is empty based on its type
        const isEmpty = () => {
            if (Array.isArray(formFieldValue)) {
                return formFieldValue.length === 0;
            }
            if (typeof formFieldValue === 'boolean') {
                return false; // A boolean value is never "empty"
            }
            if (typeof formFieldValue === 'string') {
                return !formFieldValue.trim();
            }
            return !formFieldValue;
        };

        if (isEmpty()) {
            setFormError('This field cannot be empty.');
            return;
        }

        try {
            await submitFormField({
                variables: {
                    submitFormFieldInput: {
                        taskId: task.id,
                        fieldId: task.id,
                        fieldName: task.extraData.formField.name,
                        fieldValue: Array.isArray(formFieldValue)
                            ? JSON.stringify(formFieldValue) // Convert array to string
                            : String(formFieldValue), // Convert boolean/string to string
                    },
                },
            });

            // Proceed to the next task after successful submission
            if (task.onAction) {
                task.onAction();
            } else {
                handleVerify(true);
            }
        } catch (error) {
            // Error handling is already managed in onError
        }
    };



    // Function to get task content based on type
    const getTaskContent = (type) => {
        if (type.startsWith('form_field_') || type === 'form_quiz') {
            //setFormError('');
        }

        switch (type) {
            case "common_select_reward":
                return {
                    icon: '',
                    title: quest.questTitle,
                    message: task.description,
                    action: "Select Reward",
                    onAction: () => {
                        if (!selectedReward) {
                            console.log("Please select a reward first");
                            return;
                        }
                        
                        console.log({
                            taskId: task.id,
                            taskType: task.type,
                            selectedRewardId: selectedReward
                        });

                        if (task.onAction) {
                            task.onAction();
                        } else {
                            handleVerify();
                        }
                    },
                };
            case "x_twitter_follow":
                return {
                    icon: questTwitterIcon,
                    title: task.title,
                    message: task.description,
                    action: "Follow",
                    onAction: () => {
                        console.log("Twitter follow: ", task);
                        const twitterAccount = task.extraData["Account Name"];
                        if (twitterAccount) {
                            window.open(`https://x.com/${twitterAccount}`, '_blank');
                        }
                        if (task.onAction) {
                            task.onAction();
                        }
                    },
                };
            case "x_twitter_like":
                return {
                    icon: questTwitterIcon,
                    title: task.title,
                    message: task.description,
                    action: "Like",
                    onAction: () => {
                        console.log("task 1: ", task);
                        const twitterPostLink = task.extraData["Post Link"];
                        if (twitterPostLink) {
                            window.open(twitterPostLink, '_blank');
                        }
                        if (task.onAction) {
                            task.onAction();
                        }
                    },
                };
            case "x_twitter_add_text_in_name":
                return {
                    icon: questTwitterIcon,
                    title: task.title,
                    message: task.description,
                    action: "Like",
                    onAction: () => {
                        console.log("task 1: ", task);
                        const twitterAccount = task.extraData["Text to Add to Name"];
                        if (twitterAccount) {
                            window.open(`https://x.com/${twitterAccount}`, '_blank');
                        }
                        if (task.onAction) {
                            task.onAction();
                        }
                    },
                };
            case "x_twitter_add_text_in_bio":
                return {
                    icon: questTwitterIcon,
                    title: task.title,
                    message: task.description,
                    action: "Add",
                    onAction: () => {
                        console.log("task 1: ", task);
                        const twitterAccount = task.extraData["Text to Add to Bio"];
                        if (twitterAccount) {
                            window.open(`https://x.com/${twitterAccount}`, '_blank');
                        }
                        if (task.onAction) {
                            task.onAction();
                        }
                    },
                };
            case "x_twitter_repost":
                return {
                    icon: questTwitterIcon,
                    title: task.title,
                    message: task.description,
                    action: "Add",
                    onAction: () => {
                        console.log("task 1: ", task);
                        const twitterPostLink = task.extraData["Post Link"];
                        if (twitterPostLink) {
                            window.open(twitterPostLink, '_blank');
                        }
                        if (task.onAction) {
                            task.onAction();
                        }
                    },
                };
            case "x_twitter_comment":
                return {
                    icon: questTwitterIcon,
                    title: task.title,
                    message: task.description,
                    action_message: task.extraData["Message"],
                    action: "Comment",
                    onAction: () => {
                        console.log("task 1: ", task);
                        const twitterPostLink = task.extraData["Post Link"];
                        if (twitterPostLink) {
                            window.open(twitterPostLink, '_blank');
                        }
                        if (task.onAction) {
                            task.onAction();
                        }
                    },
                };

            //
            case "telegram_join_group":
                return {
                    icon: questTwitterIcon,
                    title: task.title,
                    message: task.description,
                    action: "Join",
                    onAction: () => {
                        console.log("join TG group: ", task);
                        const tgLink = task.extraData["Invite Link"];
                        if (tgLink) {
                            window.open(tgLink, '_blank');
                        }
                        if (task.onAction) {
                            task.onAction();
                        }
                    },
                };
            case "telegram_join_channel":
                return {
                    icon: questTwitterIcon,
                    title: task.title,
                    message: task.description,
                    action: "Join",
                    onAction: () => {
                        console.log("join TG channel: ", task);
                        const tgLink = task.extraData["Invite Link"];
                        if (tgLink) {
                            window.open(tgLink, '_blank');
                        }
                        if (task.onAction) {
                            task.onAction();
                        }
                    },
                };

            //discord_join
            case "discord_join":
                return {
                    icon: questTwitterIcon,
                    title: task.title,
                    message: task.description,
                    action: "Join",
                    onAction: () => {
                        console.log("join discord: ", task);
                        const discordLink = task.extraData["Invite Link"];
                        if (discordLink) {
                            window.open(discordLink, '_blank');
                        }
                        if (task.onAction) {
                            task.onAction();
                        }
                    },
                };
            //discord_verify_roles
            case "discord_verify_roles":
                return {
                    icon: "",
                    title: task.title,
                    message: task.description,
                    action: "Verify",
                    onAction: () => {
                        console.log("verify discord roles: ", task);
                        const discordLink = task.extraData["Discord Link"];
                        if (discordLink) {
                            window.open(discordLink, '_blank');
                        }
                        if (task.onAction) {
                            task.onAction();
                        }
                    },
                };
            //web_submit_email
            case "web_submit_email":
                return {
                    icon: "",
                    title: task.title,
                    message: task.description,
                    action: "Submit",
                    onAction: async () => {
                        try {
                            await subscribeMutation({
                                variables: {
                                    subscribeInput: {
                                        email: email,
                                        campaignId: process.env.REACT_APP_CAMPAIGN_ID,
                                        taskId: task.id
                                    }
                                }
                            });

                            // Proceed to the next task after successful submission
                            if (task.onAction) {
                                task.onAction();
                            } else {
                                // Replicate the Verify button behavior
                                handleVerify();
                            }
                        } catch (error) {
                            // Handle error by setting the error message state
                            setErrorMessage(error.message);
                        }
                    },
                };
            // Add more cases as per your task types
            //add "web_verify_email"
            case "web_verify_email":
                return {
                    icon: "",
                    title: task.title,
                    message: task.description,
                    action: "Verify",
                    onAction: () => {
                        console.log("verify email: ", task);
                        handleVerify();
                    },
                };
            //add "web_verify_email
            case "form_submit":
                //const formFields = task.extraData["Form code"]
                //const formFieldsProcessed = JSON5.parse(formFields);
                //task.extraData.form = formFieldsProcessed;
                return {
                    icon: "", // Ensure you have an appropriate icon
                    title: task.title,
                    message: task.description,
                    action: "Submit Form",
                    extraData: task.extraData,

                };

            case "form_quiz":
                const questions = JSON5.parse(task.extraData["Quiz code"]);
                const currentQuestion = questions[currentQuizIndex];
                return {
                    icon: "",
                    title: `Question ${currentQuizIndex + 1} of ${questions.length}`,
                    message: currentQuestion.label,
                    action: currentQuizIndex === questions.length - 1 ? "Submit Quiz" : "Next Question",
                    onAction: handleQuizSubmission,
                    currentQuestion, // Pass the current question data
                };

            case "form_field_text":
                return {
                    icon: "",
                    title: quest.questTitle,
                    message: task.extraData.formField.label,
                    action: "Skip",
                    onAction: handleFormFieldSubmission,
                };
            case "form_field_email":
                return {
                    icon: "",
                    title: quest.questTitle,
                    message: task.extraData.formField.label,
                    action: "Skip",
                    onAction: handleFormFieldSubmission,
                };
            case "form_field_number":
                return {
                    icon: "",
                    title: quest.questTitle,
                    message: task.extraData.formField.label,
                    action: "Skip",
                    onAction: handleFormFieldSubmission,
                };
            case "form_field_textarea":
                return {
                    icon: "",
                    title: quest.questTitle,
                    message: task.extraData.formField.label,
                    action: "Skip",
                    onAction: handleFormFieldSubmission,
                };
            case "form_field_select":
                return {
                    icon: "",
                    title: quest.questTitle,
                    message: task.extraData.formField.label,
                    action: "Skip",
                    onAction: handleFormFieldSubmission,
                };
            case "form_field_radio":
                return {
                    icon: "",
                    title: quest.questTitle,
                    message: task.extraData.formField.label,
                    action: "Skip",
                    onAction: handleFormFieldSubmission,
                };
            case "form_field_checkbox":
                return {
                    icon: "",
                    title: quest.questTitle,
                    message: task.extraData.formField.label,
                    action: "Skip",
                    onAction: handleFormFieldSubmission,
                };
            case 'wallet_connect':
                return {
                    icon: '', // Add an appropriate icon if available
                    title: task.title || 'Connect Your Wallet',
                    message: task.description || 'Please connect your wallet to proceed.',
                    action: 'Connect Wallet',
                    onAction: () => {
                        // Wallet connection logic will be handled by the ConnectButton component
                    },
                };
            default:
                return {
                    icon: "",
                    title: "Complete the Task",
                    message: description || "Complete the task to move forward.",
                };
        }
    };

    const { icon, title, message, action, onAction, action_message, extraData } = getTaskContent(type);

    // Add this function to get reward images
    const getSelectedRewardImages = () => {
        if (!task.extraData?.selectedRewardIds || !rewardCategoriesData?.rewardCategories) {
            return [];
        }

        const selectedIds = task.extraData.selectedRewardIds;
        const allRewards = rewardCategoriesData.rewardCategories.flatMap(category => category.rewards);
        
        return selectedIds
            .map(id => {
                const reward = allRewards.find(r => r.id === id);
                if (!reward) return null;
                
                try {
                    const metadata = JSON.parse(reward.metadata);
                    return metadata.item?.imageUrl || null;
                } catch (e) {
                    console.error('Error parsing reward metadata:', e);
                    return null;
                }
            })
            .filter(Boolean); // Remove null values
    };

    // Add state for selected reward
    const [selectedReward, setSelectedReward] = useState(null);

    return (
        <div className={styles.main__action_info}>
            <div
                className={`${styles.main__tg} wow animate__animated animate__tada`}
            >
                <img src={questTwitterIcon} alt="TelegramIcon" />
            </div>
            <div className={styles.main__action_title}>
                {title}
            </div>
            <div className={styles.main__action_description}>
                {message}
            </div>
            {type === "form_field_text" && (
                <label className={styles.main__action_input}>
                    <input
                        type="text"
                        value={formFieldValue}
                        placeholder={task.extraData.formField.placeholder}
                        onChange={(e) => {
                            setFormFieldValue(e.target.value);
                            setFormError(''); // Clear the error when user types
                        }}
                    />
                    {formError && <div className={styles.errorMessage}>Error: {formError}</div>}
                </label>
            )}
            {type === "form_field_email" && (
                <label className={styles.main__action_input}>
                    <input
                        type="email"
                        value={formFieldValue}
                        placeholder={task.extraData.formField.placeholder}
                        onChange={(e) => {
                            setFormFieldValue(e.target.value);
                            // Clear any existing errors when user types
                            setFormError('');

                            // Basic email validation
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if (e.target.value && !emailRegex.test(e.target.value)) {
                                setFormError('Please enter a valid email address');
                            }
                        }}
                        onBlur={() => {
                            // Validate on blur for empty field
                            if (!formFieldValue) {
                                setFormError('Email is required');
                            }
                        }}
                    />
                    {formError && <div className={styles.errorMessage}>Error: {formError}</div>}
                </label>
            )}
            {type === "form_field_number" && (
                <label className={styles.main__action_input}>
                    <input
                        type="number"
                        value={formFieldValue}
                        placeholder={task.extraData.formField.placeholder}
                        onChange={(e) => setFormFieldValue(e.target.value)}
                    />
                    {formError && <div className={styles.errorMessage}>Error: {formError}</div>}
                </label>
            )}
            {type === "form_field_textarea" && (
                <label className={styles.main__action_input}>
                    <textarea
                        style={{ width: '100%', padding: '10px' }}
                        value={formFieldValue}
                        placeholder={task.extraData.formField.placeholder}
                        onChange={(e) => setFormFieldValue(e.target.value)}
                    />
                    {formError && <div className={styles.errorMessage}>Error: {formError}</div>}
                </label>
            )}

            {type === "form_field_select" && (
                <label className={styles.main__action_select_input}>
                    <select
                        value={formFieldValue}
                        placeholder={task.extraData.formField.placeholder}
                        onChange={(e) => setFormFieldValue(e.target.value)}
                        className={styles.main__action_input}
                    >
                        {task.extraData.formField.options.map((option) => (
                            <option key={option} value={option} className={styles.option}>{option}</option>
                        ))}
                    </select>
                    {formError && <div className={styles.errorMessage}>Error: {formError}</div>}
                </label>
            )}
            {type === "form_field_radio" && (
                <label className={styles.radio_label}>
                    {task.extraData.formField.options.map((option) => (
                        <div key={option}>
                            <input
                                type="radio"
                                id={`radio-${option}`}
                                name={task.extraData.formField.name} // Group radio buttons
                                value={option}
                                checked={formFieldValue === option} // Control the checked state
                                onChange={(e) => setFormFieldValue(e.target.value)}
                                className={styles.radio_input}
                            />
                            <label htmlFor={`radio-${option}`} className={styles.radio__label}>{option}</label>
                        </div>
                    ))}
                    {errorMessage && (
                        <div className={styles.errorMessage}>Error: {errorMessage}</div>
                    )}
                </label>
            )}

            {type === 'wallet_connect' && (
                <AppKitProvider>
                    <div className={styles.main__action_btn_wrapper}>
                        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                            <QueryClientProvider client={queryClient}>
                                <w3m-button />
                                <WagmiHooks />
                                {/*<button onClick={() => modal.adapter?.connectionControllerClient?.disconnect()}>Disconnect JS</button>*/}
                            </QueryClientProvider>
                        </WagmiProvider>

                    </div>
                </AppKitProvider>
            )}
            {type === 'discord_connect' && (
                <AppKitProvider>
                    <div className={styles.main__action_btn_wrapper}>
                        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                            <QueryClientProvider client={queryClient}>
                                <w3m-button />
                                <WagmiHooks />
                                {/*<button onClick={() => modal.adapter?.connectionControllerClient?.disconnect()}>Disconnect JS</button>*/}
                            </QueryClientProvider>
                        </WagmiProvider>

                    </div>
                </AppKitProvider>
            )}

            {type === 'twitter_connect' && (
                <AppKitProvider>
                    <div className={styles.main__action_btn_wrapper}>
                        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                            <QueryClientProvider client={queryClient}>
                                <w3m-button />
                                <WagmiHooks />
                                {/*<button onClick={() => modal.adapter?.connectionControllerClient?.disconnect()}>Disconnect JS</button>*/}
                            </QueryClientProvider>
                        </WagmiProvider>

                    </div>
                </AppKitProvider>
            )}

            {type === 'telegram_connect' && (
                <AppKitProvider>
                    <div className={styles.main__action_btn_wrapper}>
                        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                            <QueryClientProvider client={queryClient}>
                                <w3m-button />
                                <WagmiHooks />
                                {/*<button onClick={() => modal.adapter?.connectionControllerClient?.disconnect()}>Disconnect JS</button>*/}
                            </QueryClientProvider>
                        </WagmiProvider>

                    </div>
                </AppKitProvider>
            )}

            {type === 'google_connect' && (
                <AppKitProvider>
                    <div className={styles.main__action_btn_wrapper}>
                        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                            <QueryClientProvider client={queryClient}>
                                <w3m-button />
                                <WagmiHooks />
                                {/*<button onClick={() => modal.adapter?.connectionControllerClient?.disconnect()}>Disconnect JS</button>*/}
                            </QueryClientProvider>
                        </WagmiProvider>

                    </div>
                </AppKitProvider>
            )}

            {type === 'email_connect' && (
                <AppKitProvider>
                    <div className={styles.main__action_btn_wrapper}>
                        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                            <QueryClientProvider client={queryClient}>
                                <w3m-button />
                                <WagmiHooks />
                                {/*<button onClick={() => modal.adapter?.connectionControllerClient?.disconnect()}>Disconnect JS</button>*/}
                            </QueryClientProvider>
                        </WagmiProvider>

                    </div>
                </AppKitProvider>
            )}

            {type === 'email_connect' && (
                <AppKitProvider>
                    <div className={styles.main__action_btn_wrapper}>
                        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                            <QueryClientProvider client={queryClient}>
                                <w3m-button />
                                <WagmiHooks />
                                {/*<button onClick={() => modal.adapter?.connectionControllerClient?.disconnect()}>Disconnect JS</button>*/}
                            </QueryClientProvider>
                        </WagmiProvider>

                    </div>
                </AppKitProvider>
            )}


            {type === "form_field_checkbox" && (
                <label className={styles.checkbox_label}>
                    {task.extraData.formField.options && task.extraData.formField.options.length > 0 ? (
                        task.extraData.formField.options.map((option) => (
                            <div key={option}>
                                <input
                                    type="checkbox"
                                    id={`checkbox-${option}`}
                                    name={task.extraData.formField.name}
                                    value={option}
                                    checked={Array.isArray(formFieldValue) && formFieldValue.includes(option)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setFormFieldValue([...formFieldValue, option]);
                                        } else {
                                            setFormFieldValue(formFieldValue.filter((val) => val !== option));
                                        }
                                    }}
                                    className={styles.checkbox_input}
                                />
                                <label htmlFor={`checkbox-${option}`} className={styles.checkbox__label}>{option}</label>
                            </div>
                        ))
                    ) : (
                        <div>
                            <input
                                type="checkbox"
                                id={`checkbox-${task.extraData.formField.name}`}
                                name={task.extraData.formField.name}
                                value={task.extraData.formField.name}
                                checked={!!formFieldValue}
                                onChange={(e) => setFormFieldValue(e.target.checked)}
                                className={styles.checkbox_input}
                            />
                            <label className={styles.checkbox__label} htmlFor={`checkbox-${task.extraData.formField.name}`}>{task.extraData.formField.label}</label>
                        </div>
                    )}
                    {errorMessage && (
                        <div className={styles.errorMessage}>Error: {errorMessage}</div>
                    )}
                </label>
            )}



            {action_message && (
                <div className={styles.main__action_description}>
                    <div className={styles.main__action_comment}>Comment text:</div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>{action_message}</span>
                        <button
                            onClick={() => {
                                navigator.clipboard.writeText(action_message);
                                setCopied(true);
                                setTimeout(() => setCopied(false), 2000);
                            }}
                            style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                marginLeft: "10px",
                            }}
                        >
                            {copied ? (
                                <Check style={{ width: "20px", height: "20px", color: "white" }} />
                            ) : (
                                <Clipboard style={{ width: "20px", height: "20px", color: "white" }} />
                            )}
                        </button>

                    </div>
                </div>
            )}


            {type === "form_quiz" && (
                <>
                    {/* Display progress */}
                    <div className={styles.quiz_progress}>
                        Question {currentQuizIndex + 1} of {JSON5.parse(task.extraData["Quiz code"]).length}
                    </div>

                    {/* Render current question based on its type */}
                    {(() => {
                        const currentQuestion = JSON5.parse(task.extraData["Quiz code"])[currentQuizIndex];

                        switch (currentQuestion.type) {
                            case 'text':
                                return (
                                    <label className={styles.main__action_input}>
                                        <input
                                            type="text"
                                            value={formFieldValue}
                                            placeholder={currentQuestion.placeholder}
                                            onChange={(e) => {
                                                setFormFieldValue(e.target.value);
                                                setFormError('');
                                            }}
                                        />
                                    </label>
                                );

                            case 'number':
                                return (
                                    <label className={styles.main__action_input}>
                                        <input
                                            type="number"
                                            value={formFieldValue}
                                            placeholder={currentQuestion.placeholder}
                                            onChange={(e) => {
                                                setFormFieldValue(e.target.value);
                                                setFormError('');
                                            }}
                                        />
                                    </label>
                                );
                            case 'select':
                                return (
                                    <label className={styles.main__action_select_input}>
                                        <select
                                            value={formFieldValue}
                                            onChange={(e) => setFormFieldValue(e.target.value)}
                                            className={styles.main__action_input}
                                        >
                                            <option value="">Select an answer</option>
                                            {currentQuestion.options.map((option) => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </label>
                                );
                            case 'checkbox':
                                return (
                                    <div className={styles.checkbox_group}>
                                        {currentQuestion.options.map((option) => (
                                            <label key={option} className={styles.checkbox_label}>
                                                <input
                                                    type="checkbox"
                                                    name={currentQuestion.name}
                                                    value={option}
                                                    checked={Array.isArray(formFieldValue) && formFieldValue.includes(option)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setFormFieldValue([...formFieldValue, option]);
                                                        } else {
                                                            setFormFieldValue(formFieldValue.filter((val) => val !== option));
                                                        }
                                                        setFormError('');
                                                    }}
                                                    className={styles.checkbox_input}
                                                />
                                                <span className={styles.checkbox_text}>{option}</span>
                                            </label>
                                        ))}
                                    </div>
                                );

                            case 'textarea':
                                return (
                                    <label className={styles.main__action_input}>
                                        <textarea
                                            value={formFieldValue}
                                            placeholder={currentQuestion.placeholder}
                                            onChange={(e) => {
                                                setFormFieldValue(e.target.value);
                                                setFormError('');
                                            }}
                                            className={styles.textarea_input}
                                        />
                                    </label>
                                );
                            case 'radio':
                                return (
                                    <div className={styles.radio_group}>
                                        {currentQuestion.options.map((option) => (
                                            <label key={option} className={styles.radio_label}>
                                                <input
                                                    type="radio"
                                                    name={currentQuestion.name}
                                                    value={option}
                                                    checked={formFieldValue === option}
                                                    onChange={(e) => {
                                                        setFormFieldValue(e.target.value);
                                                        setFormError('');
                                                    }}
                                                    className={styles.radio_input}
                                                />
                                                <span className={styles.radio_text}>{option}</span>
                                            </label>
                                        ))}
                                    </div>
                                );


                            default:
                                return null;
                        }
                    })()}

                    {formError && <div className={styles.errorMessage}>Error: {formError}</div>}
                    {quizError && <div className={styles.errorMessage}>Error: {quizError}</div>}
                </>
            )}
            {type === "common_select_hero" && (

                <div
                    className={`${styles.champion__wrapper} ${styles.main__action_quest_champion}`}
                >
                    <label className={styles.radio}>
                        <div
                            className={styles.label__champion}
                        >
                            <img src={Champion1Icon} alt="Champion1Icon" />
                            <div>Kitty</div>
                        </div>
                        <input
                            type="radio"
                            name="options"
                            value="value1"

                        />
                        <span className={styles.radio__checkmark}></span>
                    </label>
                    <label className={styles.radio}>
                        <div
                            className={styles.label__champion_active}
                        /*styles.label__champion_active*/
                        >
                            <img src={Champion2Icon} alt="Champion1Icon" />
                            <div>Monkey</div>
                        </div>
                        <input
                            type="radio"
                            name="options"
                            value="value2"

                        />
                        <span className={styles.radio__checkmark}></span>
                    </label>
                    <label className={styles.radio}>
                        <div
                            className={styles.label__champion}
                        >
                            <img src={Champion3Icon} alt="Champion1Icon" />
                            <div>Pirate Joe</div>
                        </div>
                        <input
                            type="radio"
                            name="options"
                            value="value3"

                        />
                        <span className={styles.radio__checkmark}></span>
                    </label>
                </div>
            )}
            {type === "common_select_reward" && (
                <div className={`${styles.champion__wrapper} ${styles.main__action_quest_champion}`}>
                    {getSelectedRewardImages().map((imageUrl, index) => {
                        const rewardId = task.extraData.selectedRewardIds[index];
                        return (
                            <label key={rewardId} className={styles.radio}>
                                <div 
                                    className={`${styles.label__champion} ${selectedReward === rewardId ? styles.label__champion_active : ''}`}
                                    onClick={() => setSelectedReward(rewardId)}
                                >
                                    <img 
                                        src={`${process.env.REACT_APP_BACK_URL}/img/${imageUrl}`}
                                        alt={`Reward ${index + 1}`}
                                        style={{ width: '100px', height: '100px' }}
                                    />
                                </div>
                                <input
                                    type="radio"
                                    name="rewardOptions"
                                    value={rewardId}
                                    checked={selectedReward === rewardId}
                                    onChange={() => setSelectedReward(rewardId)}
                                />
                                <span className={styles.radio__checkmark}></span>
                            </label>
                        );
                    })}
                </div>
            )}

            <div className={styles.main__action_btn_wrapper}>
                <div
                    className={styles.main__action_btn}
                    onClick={() => {
                        console.log("task 2: ", task);
                        onAction && onAction();
                    }}
                >
                    {action}
                </div>
            </div>
        </div>
    );
};

TaskActionMessage.propTypes = {
    task: PropTypes.object.isRequired,
    scrollToTop: PropTypes.func.isRequired,
    handleVerify: PropTypes.func.isRequired,
};

export default TaskActionMessage;




/*
quiz example
[
  {
    "name": "firstName",
    "label": "First Name",
    "type": "text",
    "placeholder": "Enter your first name",
    "required": true
  },
  {
    "name": "email",
    "label": "Email",
    "type": "email",
    "placeholder": "Enter your email",
    "required": true
  },
  {
    "name": "age",
    "label": "Age",
    "type": "number",
    "placeholder": "Enter your age",
    "required": false
  },
  {
    "name": "bio",
    "label": "Bio",
    "type": "textarea",
    "placeholder": "Tell us about yourself",
    "required": false
  },
  {
    "name": "country",
    "label": "Country",
    "type": "select",
    "placeholder": "Select your country",
    "required": true,
    "options": ["USA", "Canada", "UK", "Australia"]
  },
  {
    "name": "gender",
    "label": "Gender",
    "type": "radio",
    "required": true,
    "options": ["Male", "Female", "Other"]
  },
  {
    "name": "terms",
    "label": "Confirm out Terms of services",
    "options": ["I confirm Terms of services"],
    "type": "checkbox",
    "required": true
  }
][
  {
    "name": "firstName",
    "label": "First Name",
    "type": "text",
    "placeholder": "Enter your first name",
    "required": true
  },
  {
    "name": "email",
    "label": "Email",
    "type": "email",
    "placeholder": "Enter your email",
    "required": true
  },
  {
    "name": "age",
    "label": "Age",
    "type": "number",
    "placeholder": "Enter your age",
    "required": false
  },
  {
    "name": "bio",
    "label": "Bio",
    "type": "textarea",
    "placeholder": "Tell us about yourself",
    "required": false
  },
  {
    "name": "country",
    "label": "Country",
    "type": "select",
    "placeholder": "Select your country",
    "required": true,
    "options": ["USA", "Canada", "UK", "Australia"]
  },
  {
    "name": "gender",
    "label": "Gender",
    "type": "radio",
    "required": true,
    "options": ["Male", "Female", "Other"]
  },
  {
    "name": "terms",
    "label": "Confirm out Terms of services",
    "options": ["I confirm Terms of services"],
    "type": "checkbox",
    "required": true
  }
]
  */


