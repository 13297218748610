import React, { useEffect, useRef, useState } from "react";
import { useQuery, gql } from '@apollo/client';
import Cookies from 'js-cookie';

import styles from "./QuestFloatingBlock.module.scss";
import CompleteIcon from "../../../../img/complete.svg";
import DiamondIcon from "../../../../img/diamond.svg";
import LeaderBoardIcon from "../../../../img/leaderboard.svg";
import UnlockIcon from "../../../../img/unlock.svg";
import LogoutIcon from "../../../../img/logout.svg";
import EditIcon from "../../../../img/edit.svg";
import GemIcon from "../../../../img/gem.svg";
import LockIcon from "../../../../img/lock.svg";
import ProgressBar from "../ProgressBar/ProgressBar";
import combineClasses from "../../../../helpers/combineClasses";
import SignUpModal from "../../../../components/SignUpModal/SignUpModal";
import { useCurrentUser } from '../../../../hooks/useCurrentUser';

function QuestFloatingBlock({ isRegistered, totalQuests }) {
  const elementRef = useRef(null);

  const handleLogout = () => {
    // Remove token from cookies using js-cookie library
    Cookies.remove('token');
    window.location.href = '/';
  };

  const [isShowBlock, setIsShowBlock] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isShowLogin, setIsShowLogin] = useState(false);

  // Replace the existing useQuery with the custom hook
  const { loading: userLoading, error: userError, currentUser, refetch } = useCurrentUser();

  // If the user is authenticated, get their ID
  const userId = currentUser?.id;

  // Define the query to get user progress
  const GET_USER_PROGRESS = gql`
    query GetUserProgress($campaignId: String!) {
      getUserProgress(campaignId: $campaignId) {
        id
        completedQuests {
          id
          quest {
            id
            title
            description
            pointsReward
          }
          completionTime
          pointsRewarded
        }
        completedTasks {
          id
          task {
            id
            title
            description
          }
          completionTime
          pointsRewarded
        }
        completedQuestGroups {
          id
          questGroup {
            id
            title
            pointsReward
          }
          completionTime
          pointsRewarded
        }
        createdAt
        updatedAt
      }
    }
  `;

  // Use useQuery for GET_USER_PROGRESS only if userId is available
  const {
    loading: progressLoading,
    error: progressError,
    data: progressData
  } = useQuery(GET_USER_PROGRESS, {
    variables: { campaignId: process.env.REACT_APP_CAMPAIGN_ID },
    skip: !userId, // Skip the query if userId is not available
    fetchPolicy: 'network-only',
  });

  console.log('progressData', progressData);
  const scrollToTarget = () => {
    const targetElement = document.getElementById("leaderboardSection");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setIsShowBlock(true);
        } else {
          setIsShowBlock(false);
        }
      },
      { threshold: 0 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  if (userLoading) {
    return <div className={styles.loading}></div>;
  }

  if (userError) {
    console.error('Error fetching current user:', userError);
    // Handle error if needed
  }

  return (
    <>
      {isShowBlock && (
        <div className={styles.block__mobile}>
          <div>
            <div className={styles.block__mobile_title}>Join waitlist</div>
            <div className={styles.block__mobile_description}>
              Complete Quests, Earn Points, Progress, Unlock Content & Rewards
            </div>
          </div>
          <div className={styles.block__btn_wrapper}>
            <div className={styles.block__btn}>Join with Email</div>
          </div>
        </div>
      )}
      <div
        className={combineClasses(
          styles,
          "block",
          !currentUser || isSessionExpired ? "block__registered" : ""
        )}
        ref={elementRef}
      >
        {!currentUser || isSessionExpired || userError ? (
          <>
            <div className={styles.block__title}>Join waitlist</div>
            <div className={styles.block__description}>
              How to get to beta and win rewards:
            </div>
            <div className={styles.block__list}>
              <div className={styles.block__list_row}>
                <div className={styles.block__list_row_icon}>
                  <img src={CompleteIcon} alt="Complete Quests" />
                </div>
                <div className={styles.block__list_row_text}>
                  Complete Quests
                </div>
              </div>
              <div className={styles.block__list_row}>
                <div className={styles.block__list_row_icon}>
                  <img src={DiamondIcon} alt="Earn Hero Points" />
                </div>
                <div className={styles.block__list_row_text}>
                  Earn Hero Points
                </div>
              </div>
              <div className={styles.block__list_row}>
                <div className={styles.block__list_row_icon}>
                  <img src={LeaderBoardIcon} alt="Progress in Leaderboard" />
                </div>
                <div className={styles.block__list_row_text}>
                  Progress in Leaderboard
                </div>
              </div>
              <div className={styles.block__list_row}>
                <div className={styles.block__list_row_icon}>
                  <img src={UnlockIcon} alt="Unlock Content & Rewards" />
                </div>
                <div className={styles.block__list_row_text}>
                  Unlock content & rewards
                </div>
              </div>
            </div>
            <div className={styles.block__btn_wrapper}>
              <div
                className={styles.block__btn}
                onClick={() => setIsShowLogin(true)}
              >
                Join with Email
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.registered__title}>
              You're on the waiting list!
            </div>

            <div className={styles.registered__info}>
              <div className={styles.registered__info_id}>
                ID #{currentUser.id}
              </div>
              <div className={styles.registered__info_main}>
                <p>{currentUser.name}</p>
                <div className={styles.registered__info_actions}>
                  <a href="/profile">
                    <img src={EditIcon} alt="Edit" />
                  </a>
                  <button onClick={handleLogout}>
                    <img src={LogoutIcon} alt="Logout" />
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.registered__stats}>
              <div className={styles.registered__row}>
                <div className={styles.registered__stats_label}>
                  Your HP balance:
                </div>
                <div className={styles.registered__stats_value}>
                  {currentUser.hpBalance}
                  <img src={GemIcon} alt="Diamond" />
                </div>
              </div>
              <div className={styles.registered__row}>
                <div className={styles.registered__stats_label}>
                  Leaderboard Position:
                </div>
                <div className={styles.registered__stats_value}>
                  {currentUser.leaderboardPosition}
                </div>
              </div>
              <div className={styles.registered__row}>
                <div className={styles.registered__stats_label}>
                  Quests Completed:
                </div>
                <div className={styles.registered__stats_value}>
                  {currentUser.questsCompleted} / {totalQuests}
                </div>
              </div>
            </div>
            <ProgressBar
              currentStep={currentUser.questsCompleted}
              totalSteps={totalQuests}
            />
            <div className={styles.referrals}>
              <div className={styles.registered__stats_label}>
                Active Referrals:
              </div>
              <div
                className={combineClasses(
                  styles,
                  "registered__stats_label",
                  "registered__stats_label_mob"
                )}
              >
                Referrals:
              </div>
              <div className={styles.registered__stats_value}>
                <div>
                  Unlocks at Lvl{currentUser.activeReferrals.levelUnlocked}
                </div>
                <img src={LockIcon} alt="Lock" />
              </div>
            </div>
            <div className={styles.registered__description}>
              Complete quests, earn Hero Points, unlock new levels and
              assignments. Advance the Leaderboard to earn exclusive rewards.
            </div>
            <div className={styles.block__btn_wrapper}>
              <div className={styles.block__btn} onClick={scrollToTarget}>
                Check Leaderboard
              </div>
            </div>
          </>
        )}
      </div>
      {isShowLogin && (
        <SignUpModal
          onClose={() => {
            setIsShowLogin(false);
            refetch(); // Refetch user data after closing modal
          }}
        />
      )}
    </>
  );
}

export default QuestFloatingBlock;