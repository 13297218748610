import React, { useEffect, useState } from "react";
import Promo from "./components/Promo/Promo";
import QuestSection from "./components/QuestSection/QuestSection";
import LeaderBoard from "./components/Leaderboard/Leaderboard";
import Faq from "./components/Faq/Faq";
import Footer from "../../components/Footer/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";

// Define the GraphQL mutation to confirm subscription by code
const CONFIRM_SUBSCRIPTION_MUTATION = gql`
  mutation ConfirmSubscription($input: ConfirmSubscriptionInput!) {
    confirmSubscription(confirmSubscriptionInput: $input) {
      success
      task {
        id
        title
        description
        extraData
        position
        quest {
          id
          title
          description
          pointsReward
          createdAt
          updatedAt
          tasks {
            id
            title
            type
            description
            extraData
            position
            # Add other Task fields as necessary
          }
        }

      }
    }
  }
`;

function QuestPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");

  // Initialize the mutation hook
  const [confirmSubscription, { data, loading, error: mutationError }] = useMutation(CONFIRM_SUBSCRIPTION_MUTATION);

  useEffect(() => {
    // Extract 'code' from query parameters
    const params = new URLSearchParams(location.search);
    const subscriptionCode = params.get("code");

    if (subscriptionCode) {
      // Execute the mutation with the required input
      confirmSubscription({
        variables: { input: { confirmationCode: subscriptionCode } }, // Corrected field name
      })
        .then(response => {
          if (response.data.confirmSubscription.success) {
            const questData = response.data.confirmSubscription.task;
            // Navigate to the specific quest page with quest data
            console.log(questData);
            navigate("/quest", {
              state: {
                questTitle: questData.title,
                questId: questData.quest.id,
                pointsReward: questData.quest.pointsReward,
                tasks: questData.quest.tasks,
              }
            });
          } else {
            // Handle unsuccessful confirmation
            setError("Subscription confirmation failed.");
          }
        })
        .catch(err => {
          // Handle errors from the mutation
          setError("Failed to confirm subscription.");
          console.error("Mutation error:", err);
        });
    } else {
      // If no 'code' is provided, navigate to the main quests page
      navigate("/quests");
    }
  }, [location.search, confirmSubscription, navigate]);

  // Effect to handle mutation errors
  useEffect(() => {
    if (mutationError) {
      setError("An error occurred while confirming your subscription.");
      console.error("ApolloError:", mutationError);
    }
  }, [mutationError]);

  // Render loading, error, or the main content
  return (
    <div>
      {loading && <p>Loading quest...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {/* The rest of your components won't render here as navigation occurs on success */}
      <Promo />
      <QuestSection />
      <LeaderBoard />
      <Faq />
      <Footer />
    </div>
  );
}

export default QuestPage;