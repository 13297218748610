import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import QuestPage from "./pages/QuestPage/QuestPage";
import CurrentQuest from "./pages/CurrentQuest/CurrentQuest";
import LoginStorage from './pages/Storage/LoginStorage';
import NotFound from './pages/NotFound/NotFound';
import Landing from "./pages/Landing/Landing";
import Profile from "./pages/Profile/Profile";

import Main from './pages/CurrentQuest/component/Main/Main';

import "animate.css/animate.min.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<LoginStorage />} />
        <Route path="/quests" element={<QuestPage />} />
        <Route path="/quests/*" element={<QuestPage />} />
        <Route path="/quest" element={<CurrentQuest />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
