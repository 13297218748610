import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ProgressBar from "../QuestPage/components/ProgressBar/ProgressBar";

import styles from './Profile.module.scss';

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useQuery, gql } from "@apollo/client";

import profilePicture from "../../img/profile-picture.svg"
import EditIcon from "../../img/edit.svg"
import LogoutIcon from "../../img/logout.svg"
import TeamIcon from "../../img/team1.svg"
import star from '../../img/star.svg'
import gem from '../../img/gem.svg'
import greenGem from '../../img/greenGem.svg'
import copyIcon from '../../img/copy.svg'
import lock from '../../img/lock.svg'
import googleSquare from '../../img/googleSquare.svg'
import discordSquare from '../../img/discordSquare.svg'
import xSquare from '../../img/xSquare.svg'
import phantomSquare from '../../img/phantomSquare.svg'
import tonSquare from '../../img/tonSquare.svg'


export default function Profile() {
    const { loading: userLoading, error: userError, currentUser, refetch } = useCurrentUser();

    if (!userLoading && !currentUser) {
        window.location.href = '/';
    }

    const GET_ALL_CAMPAIGNS = gql`
    query GetCampaigns {
      getCampaigns {
        id
        name
        description
        startTime
        endTime
      }
    }
  `;

    // Keep existing campaign query
    const GET_CAMPAIGN_DATA = gql`
    query GetCampaign($id: ID!) {
      campaign(id: $id) {
        id
        name
        description
        startTime
        endTime
        questGroups {
          id
          title
          pointsReward
          unlocksAt
          position
          quests {
            id
            title
            description
            pointsReward
            createdAt
            updatedAt
            position
            tasks {
              id
              title
              description
              type
              status
              extraData
              createdAt
              updatedAt
              position
            }
          }
        }
      }
    }
  `;

    const { loading: campaignsLoading, error: campaignsError, data: campaignsData } = useQuery(GET_ALL_CAMPAIGNS);
    const { loading: campaignDetailsLoading, error: campaignDetailsError, data: campaignDetailsData } = useQuery(GET_CAMPAIGN_DATA, {
        variables: {
            id: campaignsData?.getCampaigns?.[0]?.id
        },
        skip: !campaignsData?.getCampaigns?.[0]?.id,
    });

    const { campaign } = campaignDetailsData || {};

    const totalQuests = campaign?.questGroups?.reduce(
        (accumulator, group) => accumulator + group.quests.length,
        0
    );

    console.log(currentUser);
    return (
        <>
            <Header />
            <div className={styles.profile}>
                <div className="container">
                    <div className={styles.profile__inner}>
                        <aside className={styles.profile__aside}>
                            <img src={profilePicture} alt="profile picture" />
                            <div className={styles.profile__aside_info}>
                                <div className={styles.profile__aside_id}>
                                    ID #{currentUser?.id}
                                </div>
                                <div className={styles.profile__aside_name}>
                                    <p>{currentUser?.name}</p>

                                    <div>
                                        <button>
                                            <img src={EditIcon} alt="edit" />
                                        </button>
                                        <button>
                                            <img src={LogoutIcon} alt="logout" />
                                        </button>
                                    </div>
                                </div>
                                <div className={styles.profile__aside_team}>
                                    <img src={TeamIcon} alt="team" />
                                    <p>CryptoMonkeys</p>
                                </div>
                                <div className={styles.profile__aside_badge}>
                                    <img src={star} alt="star" />
                                    <p>Early-Tester NFT</p>
                                </div>
                            </div>
                            <div className={styles.profile__aside_balance}>
                                <div>
                                    <span>HP balance</span>
                                    <div>{currentUser?.hpBalance} <img src={gem} alt="gem" /></div>
                                </div>
                                <div>
                                    <span>XP balance</span>
                                    <div>3050 <img src={greenGem} alt="greenGem" /></div>
                                </div>
                            </div>
                            <div className={styles.profile__aside_refferals}>
                                <div className={styles.profile__aside_refferals_title}>
                                    <p>Your referrals</p>
                                    <span>{currentUser?.activeReferrals?.count}</span>
                                </div>
                                <div className={styles.profile__aside_refferals_actions}>
                                    <button className={styles.profile__aside_refferals_actions_btn}>Send your refferal link</button>
                                    <button className={styles.profile__aside_refferals_actions_copy}>
                                        <img src={copyIcon} alt="copy" />
                                    </button>
                                </div>
                            </div>
                        </aside>
                        <section className={styles.profile__main}>
                            <div className={styles.profile__main_position}>
                                <p>Leaderboard position:</p>
                                <span>{currentUser?.leaderboardPosition}</span>
                            </div>
                            <div className={styles.profile__main_referrals}>
                                <p>Active referrals:</p>
                                <div>Unlocks at Lvl3 <img src={lock} alt="lock" /></div>
                            </div>
                            <div className={styles.profile__main_completed}>
                                <div className={styles.profile__main_completed_title}>
                                    <p>Quests completed:</p>
                                    <span>{currentUser?.questsCompleted}/{totalQuests}</span>
                                </div>
                                <ProgressBar currentStep={currentUser?.questsCompleted} totalSteps={totalQuests} />
                            </div>
                            <div className={styles.profile__main_wallet}>
                                <p>0x4cc2...6dac80</p>
                                <button className={styles.connect_btn}>
                                    Connect
                                </button>
                            </div>
                            <div className={styles.profile__main_socials}>
                                <div>
                                    <div>
                                        <img src={googleSquare} alt="google" />
                                        <p>user@gmail.com</p>
                                    </div>
                                    <button className={styles.connect_btn}>
                                        Connect
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <img src={xSquare} alt="x" />
                                        <p>/user12345</p>
                                    </div>
                                    <button className={styles.connect_btn}>
                                        Connect
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <img src={discordSquare} alt="discord" />
                                        <p>Discord</p>
                                    </div>
                                    <button className={styles.connect_btn}>
                                        Connect
                                    </button>
                                </div>
                            </div>

                            <div className={styles.profile__main_wallets}>
                                <div>
                                    <div>
                                        <img src={phantomSquare} alt="phantom" />
                                        <p>0x4cc2...6dac80</p>
                                    </div>
                                    <button className={styles.connect_btn}>
                                        Connect
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <img src={tonSquare} alt="ton" />
                                        <p>Tonkeeper</p>
                                    </div>
                                    <button className={styles.connect_btn}>
                                        Connect
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <img src={tonSquare} alt="ton" />
                                        <p>Metamask</p>
                                    </div>
                                    <button className={styles.connect_btn}>
                                        Connect
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <img src={tonSquare} alt="ton" />
                                        <p>Petra Aptos wallet</p>
                                    </div>
                                    <button className={styles.connect_btn}>
                                        Connect
                                    </button>
                                </div>
                                <div>
                                    <div>
                                        <img src={tonSquare} alt="ton" />
                                        <p>Pontem Crypto wallet</p>
                                    </div>
                                    <button className={styles.connect_btn}>
                                        Connect
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}