import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import styles from "./NotFound.module.scss";
import notFound from "../../img/notFound.svg"

export default function NotFound() {

    return (
        <div>
            <Header />
            <div className={styles.notFound}>
                <div className="container">
                    <div className={styles.notFound__inner}>
                        <img src={notFound} alt="notFound" />
                        <div className={styles.notFound__title}>The Page You Were Looking For Doesn&apos;t Exist.</div>
                        <a href="/" className={styles.notFound__link}>Go back</a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

