// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  box-sizing: border-box;
  background: #40187A;
}

.container {
  max-width: 1138px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (max-width: 991px) {
  .container {
    max-width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":["body {\n  box-sizing: border-box;\n  background: #40187A;\n}\n\n.container {\n  max-width: 1138px;\n  width: 100%;\n  margin: 0 auto;\n}\n\n@media screen and (max-width: 1200px) {\n  .container {\n    max-width: 960px;\n  }\n}\n\n@media screen and (max-width: 991px) {\n  .container {\n    max-width: 100%;\n    padding-left: 8px;\n    padding-right: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
