// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Status_status__VzUCS {
  width: 173px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  box-shadow: 0px 4px 0px 0px #000;
  transition: 0.2s ease-in-out;
}
.Status_status__VzUCS > div {
  color: #fff;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-family: November;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: capitalize;
}
.Status_status__VzUCS:hover {
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}

.Status_status__VzUCS.Status_completed__t-\\+eu {
  border: 1px solid #08bb19;
  background: #00721f;
}

.Status_status__VzUCS.Status_inprogress__oKo6m {
  border: 1px solid #f7921d;
  background: #f7921d;
}

.Status_status__VzUCS.Status_gray__1o67u {
  border: 1px solid #7d4dff;
  background: #270659;
}
.Status_status__VzUCS.Status_gray__1o67u > div {
  color: #bebebf;
}`, "",{"version":3,"sources":["webpack://./src/pages/Landing/components/Roadmap/components/Status/Status.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;EACA,4BAAA;AACF;AAAE;EACE,WAAA;EACA,kBAAA;EACA,8BAAA;EACA,+BAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;AAEJ;AAAE;EACE,qBAAA;EACA,4BAAA;AAEJ;;AAEA;EACE,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;AACF;;AACA;EACE,yBAAA;EACA,mBAAA;AAEF;AADE;EACE,cAAA;AAGJ","sourcesContent":[".status {\n  width: 173px;\n  height: 53px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 32px;\n  box-shadow: 0px 4px 0px 0px #000;\n  transition: 0.2s ease-in-out;\n  > div {\n    color: #fff;\n    text-align: center;\n    -webkit-text-stroke-width: 1px;\n    -webkit-text-stroke-color: #000;\n    font-family: November;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 120%;\n    text-transform: capitalize;\n  }\n  &:hover {\n    transform: scale(1.1);\n    transition: 0.2s ease-in-out;\n  }\n}\n\n.status.completed {\n  border: 1px solid #08bb19;\n  background: #00721f;\n}\n\n.status.inprogress {\n  border: 1px solid #f7921d;\n  background: #f7921d;\n}\n.status.gray {\n  border: 1px solid #7d4dff;\n  background: #270659;\n  > div {\n    color: #bebebf;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `Status_status__VzUCS`,
	"completed": `Status_completed__t-+eu`,
	"inprogress": `Status_inprogress__oKo6m`,
	"gray": `Status_gray__1o67u`
};
export default ___CSS_LOADER_EXPORT___;
