import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const LoginStorage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        console.log(token);
        if (token) {
            Cookies.set('token', token, { secure: true, sameSite: 'strict' });
            navigate('/quests');
        } else {
            console.error('Token not found in URL');
        }
    }, [location, navigate]);

    return (
        <div>
            Storing your login token...
        </div>
    );
};

export default LoginStorage;
