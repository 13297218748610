import React from "react";

import styles from "./ActionStep.module.scss";
import LockIcon from "../../../../img/lock.svg";
import combineClasses from "../../../../helpers/combineClasses";
import CheckIcon from "../../../../img/completeGreen.svg";

function ActionStep({ actionInfo, isCompleted }) {
  return (
    <div
      className={combineClasses(
        styles,
        "action",
        isCompleted ? "action__completed" : ""
      )}
    >
      <div className={styles.action__info}>
        <div className={styles.action__number}>{actionInfo.position}</div>
        <div className={styles.action__title}>{actionInfo.title}</div>
      </div>
      {isCompleted ? (
        <img src={CheckIcon} alt="CheckIcon" />
      ) : isCompleted === false ? (
        <img src={LockIcon} alt="LockIcon" />
      ) : null}
    </div>
  );
}

export default ActionStep;
