// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Faq_faq__6s4o2 {
  background: #40187a;
  padding: 83px 0 139px;
  overflow: hidden;
}
.Faq_faq__container__Phssw {
  max-width: 759px;
  width: 100%;
  margin-left: auto;
  position: relative;
}
.Faq_faq__title__bCTQp {
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-size: 44px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.88px;
  text-transform: capitalize;
  position: relative;
  z-index: 3;
  font-family: November;
}
.Faq_faq__list__PDiKm {
  margin-top: 24px;
  position: relative;
  z-index: 3;
}
.Faq_faq__bg__eIHij {
  position: absolute;
  top: -104px;
  left: -64%;
}

@media screen and (max-width: 1200px) {
  .Faq_faq__container__Phssw {
    max-width: 600px;
  }
}
@media screen and (max-width: 991px) {
  .Faq_faq__6s4o2 {
    padding: 39px 8px 91px 13px;
  }
  .Faq_faq__title__bCTQp {
    font-size: 40px;
  }
  .Faq_faq__container__Phssw {
    max-width: 100%;
  }
  .Faq_faq__bg__eIHij {
    top: 107px;
    left: auto;
    right: -50px;
  }
}
@media screen and (max-width: 576px) {
  .Faq_faq__6s4o2 {
    padding: 100px 0px 100px 0px;
  }
  .Faq_faq__bg__eIHij {
    top: -100px;
    left: auto;
    right: -50px;
    width: 465px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/QuestPage/components/Faq/Faq.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;AACF;AAAE;EACE,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,WAAA;EACA,8BAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,0BAAA;EACA,kBAAA;EACA,UAAA;EACA,qBAAA;AAEJ;AAAE;EACE,gBAAA;EACA,kBAAA;EACA,UAAA;AAEJ;AAAE;EACE,kBAAA;EACA,WAAA;EACA,UAAA;AAEJ;;AAEA;EAEI;IACE,gBAAA;EAAJ;AACF;AAIA;EACE;IACE,2BAAA;EAFF;EAGE;IACE,eAAA;EADJ;EAGE;IACE,eAAA;EADJ;EAGE;IACE,UAAA;IACA,UAAA;IACA,YAAA;EADJ;AACF;AAKA;EACE;IACE,4BAAA;EAHF;EAIE;IACE,WAAA;IACA,UAAA;IACA,YAAA;IACA,YAAA;EAFJ;AACF","sourcesContent":[".faq {\n  background: #40187a;\n  padding: 83px 0 139px;\n  overflow: hidden;\n  &__container {\n    max-width: 759px;\n    width: 100%;\n    margin-left: auto;\n    position: relative;\n  }\n  &__title {\n    color: #fff;\n    -webkit-text-stroke-width: 1px;\n    -webkit-text-stroke-color: #000;\n    font-size: 44px;\n    font-weight: 500;\n    line-height: 120%;\n    letter-spacing: 0.88px;\n    text-transform: capitalize;\n    position: relative;\n    z-index: 3;\n    font-family: November;\n  }\n  &__list {\n    margin-top: 24px;\n    position: relative;\n    z-index: 3;\n  }\n  &__bg {\n    position: absolute;\n    top: -104px;\n    left: -64%;\n  }\n}\n\n@media screen and (max-width: 1200px) {\n  .faq {\n    &__container {\n      max-width: 600px;\n    }\n  }\n}\n\n@media screen and (max-width: 991px) {\n  .faq {\n    padding: 39px 8px 91px 13px;\n    &__title {\n      font-size: 40px;\n    }\n    &__container {\n      max-width: 100%;\n    }\n    &__bg {\n      top: 107px;\n      left: auto;\n      right: -50px;\n    }\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .faq {\n    padding: 100px 0px 100px 0px;\n    &__bg {\n      top: -100px;\n      left: auto;\n      right: -50px;\n      width: 465px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faq": `Faq_faq__6s4o2`,
	"faq__container": `Faq_faq__container__Phssw`,
	"faq__title": `Faq_faq__title__bCTQp`,
	"faq__list": `Faq_faq__list__PDiKm`,
	"faq__bg": `Faq_faq__bg__eIHij`
};
export default ___CSS_LOADER_EXPORT___;
