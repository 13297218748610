// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeaderBoardBadge_badge__7i-1e {
  border-radius: 2px;
  border: 2px solid #fc0;
  display: flex;
  column-gap: 12px;
  padding: 8px;
  width: 25%;
  transition: 0.2s ease-in-out;
}
.LeaderBoardBadge_badge__title__7VTkT {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 104%;
  text-transform: uppercase;
  font-family: November;
}
.LeaderBoardBadge_badge__text__8U4b1 {
  color: #a7cff4;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.LeaderBoardBadge_badge__text__8U4b1:last-child {
  font-weight: 700;
}
.LeaderBoardBadge_badge__7i-1e:hover {
  transition: 0.2s ease-in-out;
  transform: scale(1.1);
}

@media screen and (max-width: 991px) {
  .LeaderBoardBadge_badge__title__7VTkT {
    font-size: 16px;
  }
  .LeaderBoardBadge_badge__text__8U4b1 {
    font-size: 11px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/QuestPage/components/LeaderBoardBadge/LeaderBoardBadge.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;EACA,4BAAA;AACF;AAAE;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,qBAAA;AAEJ;AAAE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AADI;EACE,gBAAA;AAGN;AAAE;EACE,4BAAA;EACA,qBAAA;AAEJ;;AAEA;EAEI;IACE,eAAA;EAAJ;EAEE;IACE,eAAA;EAAJ;AACF","sourcesContent":[".badge {\n  border-radius: 2px;\n  border: 2px solid #fc0;\n  display: flex;\n  column-gap: 12px;\n  padding: 8px;\n  width: 25%;\n  transition: 0.2s ease-in-out;\n  &__title {\n    color: #fff;\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 104%;\n    text-transform: uppercase;\n    font-family: November;\n  }\n  &__text {\n    color: #a7cff4;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n    &:last-child {\n      font-weight: 700;\n    }\n  }\n  &:hover {\n    transition: 0.2s ease-in-out;\n    transform: scale(1.1);\n  }\n}\n\n@media screen and (max-width: 991px) {\n  .badge {\n    &__title {\n      font-size: 16px;\n    }\n    &__text {\n      font-size: 11px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `LeaderBoardBadge_badge__7i-1e`,
	"badge__title": `LeaderBoardBadge_badge__title__7VTkT`,
	"badge__text": `LeaderBoardBadge_badge__text__8U4b1`
};
export default ___CSS_LOADER_EXPORT___;
