// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_notFound__BEDid {
  min-height: calc(100vh - 394.5px);
}
.NotFound_notFound__inner__fOzBj {
  display: grid;
  place-items: center;
  padding-block: 124px;
  row-gap: 24px;
}
.NotFound_notFound__title__KyzyV {
  font-family: November;
  font-size: 24px;
  color: #fff;
  -webkit-text-stroke: 1px #000;
  text-align: center;
}
.NotFound_notFound__link__\\+RmVM {
  font-size: 18px;
  font-family: November;
  background: #651FFF;
  padding: 12px 40px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset, 0px 5px 0px 0px rgba(255, 255, 255, 0.2) inset;
  border: 2px solid #000;
  border-bottom: 4px solid #000;
  text-shadow: 0px 2px 0px #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #121219;
  transition: 0.2s all ease;
}
.NotFound_notFound__link__\\+RmVM:hover {
  box-shadow: none;
}

@media screen and (max-width: 565px) {
  .NotFound_notFound__inner__fOzBj {
    padding-block: 80px;
  }
  .NotFound_notFound__inner__fOzBj img {
    width: 80%;
  }
  .NotFound_notFound__title__KyzyV {
    font-size: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/NotFound.module.scss"],"names":[],"mappings":"AAAA;EACI,iCAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,aAAA;AACR;AAEI;EACI,qBAAA;EACA,eAAA;EACA,WAAA;EACA,6BAAA;EACA,kBAAA;AAAR;AAGI;EACI,eAAA;EACA,qBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,qBAAA;EACA,WAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qGAAA;EACA,sBAAA;EACA,6BAAA;EACA,6BAAA;EACA,8BAAA;EACA,kCAAA;EACA,yBAAA;AADR;AAGQ;EACI,gBAAA;AADZ;;AAMA;EAEQ;IACI,mBAAA;EAJV;EAMU;IACI,UAAA;EAJd;EAQM;IACI,eAAA;EANV;AACF","sourcesContent":[".notFound {\n    min-height: calc(100vh - 394.5px);\n\n    &__inner {\n        display: grid;\n        place-items: center;\n        padding-block: 124px;\n        row-gap: 24px;\n    }\n\n    &__title {\n        font-family: November;\n        font-size: 24px;\n        color: #fff;\n        -webkit-text-stroke: 1px #000;\n        text-align: center;\n    }\n\n    &__link {\n        font-size: 18px;\n        font-family: November;\n        background: #651FFF;\n        padding: 12px 40px;\n        text-transform: uppercase;\n        text-decoration: none;\n        color: #fff;\n        border-radius: 10px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        box-shadow: 0px -5px 0px 0px #00000033 inset, 0px 5px 0px 0px #FFFFFF33 inset;\n        border: 2px solid #000;\n        border-bottom: 4px solid #000;\n        text-shadow: 0px 2px 0px #000;\n        -webkit-text-stroke-width: 1px;\n        -webkit-text-stroke-color: #121219;\n        transition: 0.2s all ease;\n\n        &:hover {\n            box-shadow: none;\n        }\n    }\n}\n\n@media screen and (max-width: 565px) {\n    .notFound {\n        &__inner {\n            padding-block: 80px;\n\n            img {\n                width: 80%;\n            }\n        }\n\n        &__title {\n            font-size: 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": `NotFound_notFound__BEDid`,
	"notFound__inner": `NotFound_notFound__inner__fOzBj`,
	"notFound__title": `NotFound_notFound__title__KyzyV`,
	"notFound__link": `NotFound_notFound__link__+RmVM`
};
export default ___CSS_LOADER_EXPORT___;
