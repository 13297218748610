// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Landing_landing__J39y- {
  background: #40187a;
}`, "",{"version":3,"sources":["webpack://./src/pages/Landing/Landing.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".landing {\n  background: #40187a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing": `Landing_landing__J39y-`
};
export default ___CSS_LOADER_EXPORT___;
