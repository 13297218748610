import React, { useState } from "react";
import styles from "./SignUpModal.module.scss";
import CloseIcon from "../../img/close.svg";
import GoogleIcon from "../../img/googleFooter.svg";
import WalletIcon from "../../img/wallet.svg";
import ConfirmEmailIcon from "../../img/confirmEmail.svg";
import ConfirmEmailIconCharacter from "../../img/confirmEmailCharacter.svg";
import DiscordIcon from "../../img/discord.svg";
import TwitterIcon from "../../img/twitter.svg";
import { gql, useMutation } from "@apollo/client";

const LOGIN_USER = gql`
  mutation LoginUser($email: String!) {
    loginUser(email: $email)
  }
`;

function SignUpModal({ onClose }) {
    const [email, setEmail] = useState("");
    const [isTerms, setIsTerms] = useState(false);
    const [isSubscribe, setIsSubscribe] = useState(false);
    const [isShowConfirmEmail, setIsShowConfirmEmail] = useState(false);
    const [loginMessage, setLoginMessage] = useState("");
    const [loginError, setLoginError] = useState("");

    const [loginUser, { loading }] = useMutation(LOGIN_USER, {
        onCompleted: (data) => {
            setLoginMessage(data.loginUser);
            setLoginError("");
            setEmail(""); // Clear email field on success
            setIsShowConfirmEmail(true); // Show confirmation modal
        },
        onError: (error) => {
            setLoginError(error.message);
            setLoginMessage("");
        },
    });

    const handleSignUp = () => {
        if (email.trim() === "") {
            setLoginError("Email field cannot be empty.");
            return;
        }
        // Simple email regex for validation
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(email)) {
            setLoginError("Please enter a valid email address.");
            return;
        }
        // Execute the loginUser mutation
        loginUser({ variables: { email } });
    };

    const handleReSendConfirmation = () => {
        // Re-send the login link
        if (email.trim() === "") {
            setLoginError("Email field cannot be empty.");
            return;
        }
        loginUser({ variables: { email } });
    };

    // Handle body scroll when modal is open
    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "";
        };
    }, []);

    return (
        <div className={styles.modal}>
            <div
                className={styles.modal__close}
                onClick={() => {
                    onClose();
                    setLoginMessage("");
                    setLoginError("");
                }}
            >
                Close
                <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <div className={styles.modal__wrapper}>
                {!isShowConfirmEmail ? (
                    <>
                        <div className={styles.modal__title}>Sign up</div>
                        <label className={styles.modal__input}>
                            <div>Enter valid email</div>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="account@domain.com"
                            />
                        </label>
                        {loginMessage && (
                            <div
                                className={`${styles.modal__message} ${styles.modal__success}`}
                                role="alert"
                                aria-live="assertive"
                            >
                                {loginMessage}
                            </div>
                        )}
                        {loginError && (
                            <div
                                className={`${styles.modal__message} ${styles.modal__error}`}
                                role="alert"
                                aria-live="assertive"
                            >
                                {loginError}
                            </div>
                        )}
                        <div className={styles.checkboxes}>
                            <label className={styles.checkbox}>
                                <input
                                    type="checkbox"
                                    checked={isTerms}
                                    onChange={(e) => setIsTerms(e.target.checked)}
                                />
                                <span className={styles.checkmark}></span>
                                <span className={styles.checkbox_text}>
                                    I agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
                                </span>
                            </label>
                            <label className={styles.checkbox}>
                                <input
                                    type="checkbox"
                                    checked={isSubscribe}
                                    onChange={(e) => setIsSubscribe(e.target.checked)}
                                />
                                <span className={styles.checkmark}></span>
                                <span className={styles.checkbox_text}>
                                    Subscribe to receive company news and product updates from Aptools. You may unsubscribe at any time
                                </span>
                            </label>
                        </div>
                        <div
                            className={`${styles.modal__btn_wrapper} ${isTerms && email.trim() !== "" && !loading ? "" : styles.disabled
                                }`}
                            onClick={() => {
                                if (isTerms && email.trim() !== "" && !loading) {
                                    handleSignUp();
                                }
                            }}
                        >
                            <div className={styles.modal__btn}>
                                {loading ? "Sending..." : "Sign up"}
                            </div>
                        </div>
                        <div className={styles.modal__account}>
                            <div>Already have an account?</div>
                            <a href="#">Sign in</a>
                        </div>
                        <div className={styles.modal__footer}>
                            <div className={styles.modal__footer_title}>OR SIGN IN WITH</div>
                            <div className={styles.modal__footer_wrapper}>
                                <div className={styles.modal__footer_block}>
                                    <div className={styles.modal__footer_block_google}>
                                        <img src={GoogleIcon} alt="GoogleIcon" />
                                    </div>
                                    <div className={styles.modal__footer_block_text}>Google</div>
                                </div>
                                <div className={styles.modal__footer_block}>
                                    <div className={styles.modal__footer_block_discord}>
                                        <img src={DiscordIcon} alt="DiscordIcon" />
                                    </div>
                                    <div className={styles.modal__footer_block_text}>Discord</div>
                                </div>
                                <div className={styles.modal__footer_block}>
                                    <div className={styles.modal__footer_block_x}>
                                        <img src={TwitterIcon} alt="XIcon" />
                                    </div>
                                    <div className={styles.modal__footer_block_text}>X</div>
                                </div>
                                <div className={styles.modal__footer_block}>
                                    <div className={styles.modal__footer_block_wallet}>
                                        <img src={WalletIcon} alt="WalletIcon" />
                                    </div>
                                    <div className={styles.modal__footer_block_text}>Wallet</div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    // Confirm Email Modal Content
                    <div className={styles.modal__confirm_content}>
                        <div className={styles.modal__title}>Confirm your Email</div>
                        <div className={styles.modal__confirm_wrapper}>
                            <img src={ConfirmEmailIcon} alt="ConfirmEmailIcon" />
                            <img
                                className={`${styles.modal__confirm_wrapper_character} wow animate__animated animate__fadeInRight`}
                                src={ConfirmEmailIconCharacter}
                                alt="ConfirmEmailIconCharacter"
                            />
                        </div>
                        <div className={styles.modal__confirm_label}>
                            Please check your inbox for a confirmation email. <br />
                            Click the link in the email to confirm your email address.
                        </div>
                        <div
                            className={styles.modal__btn_wrapper}
                            onClick={handleReSendConfirmation}
                        >
                            <div className={styles.modal__btn}>Re-send confirmation email</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SignUpModal;