import React, { useCallback } from "react";

import styles from "./QuestCard.module.scss";
import TelegramIcon from "../../../../img/telegram.svg";
import combineClasses from "../../../../helpers/combineClasses";
import GemIcon from "../../../../img/gem.svg";
import LockedIcon from "../../../../img/lock.svg";
import { useNavigate } from "react-router-dom";

function QuestCard({ item, isLocked = false, color = "blue", index }) {
  const navigate = useNavigate();
  const {
    //name,
    pointsReward,
    progressSteps,
    progressCompleted,
    image,
    isNeedGem = true,
  } = item;

  const progressBar = Array(progressSteps).fill(null);
  /*
    const navigateToQuest = useCallback(() => {
      if (index === 0) {
        navigate("/quest", { state: { questType: 0 } });
      } else if (index === 1) {
        // navigate("/quest", { state: { questType: 1 } });
        navigate("/quest", { state: { questType: "x" } });
      } else if (index === 2) {
        // navigate("/quest", { state: { questType: 2 } });
        navigate("/quest", { state: { questType: "discord" } });
      } else if (index === 3) {
        // navigate("/quest", { state: { questType: 3 } });
        navigate("/quest", { state: { questType: "telegram" } });
      } else if (index === 4) {
        navigate("/quest", { state: { questType: "welcome" } });
      } else if (index === 5) {
        navigate("/quest", { state: { questType: "wallet" } });
      } else if (index === "Select a Champion") {
        navigate("/quest", { state: { questType: "champion" } });
      } else if (index === "Choose your team") {
        navigate("/quest", { state: { questType: "team" } });
      } else if (index === "Earn Invitation to Tournament") {
        navigate("/quest", { state: { questType: "invitation" } });
      } else if (index === "Earn Invitation to Tournament") {
        navigate("/quest", { state: { questType: "invitation" } });
      } else if (index === "Invite friends (Unlimited)") {
        navigate("/quest", { state: { questType: "invite" } });
      } else if (index === "Create Content (UGC)") {
        navigate("/quest", { state: { questType: "content" } });
      } else if (index === "Learn & Answer Quiz") {
        navigate("/quest", { state: { questType: "learn" } });
      }
  
      return null;
    }, [index]);
  */
  const navigateToQuest = useCallback(() => {
    navigate("/quest", {
      state: {
        questTitle: item.title,
        questId: item.id,
        pointsReward: item.pointsReward,
        progressSteps: item.progressSteps,
        progressCompleted: item.progressCompleted,
        image: item.image,
        questGroupTitle: item.questGroupTitle,
        tasks: item.tasks,
      }
    });
  }, [navigate, item]);

  return (
    <div
      className={combineClasses(
        styles,
        "card",
        color === "purple"
          ? "card__purple"
          : color === "pink"
            ? "card__pink"
            : ""
      )}
      onClick={navigateToQuest}
    >
      {isLocked ? (
        <div className={styles.card__locked}>
          <img src={LockedIcon} alt="LockedIcon" />
        </div>
      ) : null}
      <img
        className={styles.card__image}
        src={image || TelegramIcon}
        alt="telegram"
      />
      <div className={styles.card__title}>{item.title}</div>
      <div className={styles.card__bar}>
        {progressBar?.map((_, index) => {
          return (
            <div
              className={combineClasses(
                styles,
                "card__bar_line",
                index + 1 <= progressCompleted ? "card__bar_line_active" : ""
              )}
            ></div>
          );
        })}
      </div>
      <div className={styles.card__points}>
        <div>
          <span>+</span>
          {pointsReward}
        </div>
        {isNeedGem ? (
          <>
            <img src={GemIcon} alt="gem" />
            <div>HP</div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default QuestCard;
