// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar_bar__LUNJT {
  width: 100%;
  border: 1px solid #000;
  background: #212121;
  box-shadow: 0px -3px 0px 0px #3e3e57 inset;
  overflow: hidden;
  height: 9px;
}
.ProgressBar_bar__current__BdwP5 {
  border: 1px solid #000;
  background: #0f0;
  box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset;
  height: 100%;
  transition: width 0.3s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/pages/QuestPage/components/ProgressBar/ProgressBar.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,0CAAA;EACA,gBAAA;EACA,WAAA;AACF;AAAE;EACE,sBAAA;EACA,gBAAA;EACA,sDAAA;EACA,YAAA;EACA,kCAAA;AAEJ","sourcesContent":[".bar {\n  width: 100%;\n  border: 1px solid #000;\n  background: #212121;\n  box-shadow: 0px -3px 0px 0px #3e3e57 inset;\n  overflow: hidden;\n  height: 9px;\n  &__current {\n    border: 1px solid #000;\n    background: #0f0;\n    box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset;\n    height: 100%;\n    transition: width 0.3s ease-in-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": `ProgressBar_bar__LUNJT`,
	"bar__current": `ProgressBar_bar__current__BdwP5`
};
export default ___CSS_LOADER_EXPORT___;
