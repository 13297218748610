// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../img/promoBg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Promo_promo__2RRq\\+ {
  padding: 0 0 36px 0;
  height: 532px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Promo_promo__title__6x\\+qF {
  font-family: November;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-size: 44px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.88px;
  text-transform: capitalize;
  padding-right: 70px;
  text-align: right;
}

@media screen and (max-width: 1200px) {
  .Promo_promo__title__6x\\+qF {
    max-width: 620px;
    margin-left: auto;
  }
}
@media screen and (max-width: 991px) {
  .Promo_promo__2RRq\\+ {
    height: 496px;
  }
  .Promo_promo__title__6x\\+qF {
    max-width: calc(100% - 279px);
    padding-right: 0;
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .Promo_promo__title__6x\\+qF {
    max-width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .Promo_promo__2RRq\\+ {
    height: 400px;
    padding-bottom: 86px;
  }
  .Promo_promo__title__6x\\+qF {
    font-size: 40px;
    max-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/QuestPage/components/Promo/Promo.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,mDAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AACF;AAAE;EACE,qBAAA;EACA,WAAA;EACA,8BAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,0BAAA;EACA,mBAAA;EACA,iBAAA;AAEJ;;AAEA;EAEI;IACE,gBAAA;IACA,iBAAA;EAAJ;AACF;AAIA;EACE;IACE,aAAA;EAFF;EAGE;IACE,6BAAA;IACA,gBAAA;IACA,gBAAA;EADJ;AACF;AAKA;EAEI;IACE,eAAA;EAJJ;AACF;AAQA;EACE;IACE,aAAA;IACA,oBAAA;EANF;EAOE;IACE,eAAA;IACA,eAAA;EALJ;AACF","sourcesContent":[".promo {\n  padding: 0 0 36px 0;\n  height: 532px;\n  background: url(\"../../../../img/promoBg.png\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  &__title {\n    font-family: November;\n    color: #fff;\n    -webkit-text-stroke-width: 1px;\n    -webkit-text-stroke-color: #000;\n    font-size: 44px;\n    font-weight: 500;\n    line-height: 110%;\n    letter-spacing: 0.88px;\n    text-transform: capitalize;\n    padding-right: 70px;\n    text-align: right;\n  }\n}\n\n@media screen and (max-width: 1200px) {\n  .promo {\n    &__title {\n      max-width: 620px;\n      margin-left: auto;\n    }\n  }\n}\n\n@media screen and (max-width: 991px) {\n  .promo {\n    height: 496px;\n    &__title {\n      max-width: calc(100% - 279px);\n      padding-right: 0;\n      text-align: left;\n    }\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .promo {\n    &__title {\n      max-width: 100%;\n    }\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .promo {\n    height: 400px;\n    padding-bottom: 86px;\n    &__title {\n      font-size: 40px;\n      max-width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promo": `Promo_promo__2RRq+`,
	"promo__title": `Promo_promo__title__6x+qF`
};
export default ___CSS_LOADER_EXPORT___;
