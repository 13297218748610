import styles from "./ModalSuccess.module.scss";
import { useNavigate } from "react-router-dom";

import CloseIcon from "../../../../../../img/close.svg";
import championImage from "../../../../../../img/champion.svg";

export default function ModalSuccess({ modalSuccessInfo, setModalSuccessInfo }) {

    const navigate = useNavigate();

    return (
        <div className={styles.modal}>
            <div className={styles.modal__block}>
                <div
                    className={styles.modal__close}
                    onClick={() => {
                        setModalSuccessInfo({
                            isOpen: false,
                            image: null,
                            rewards: null,
                        });
                        navigate("/quests"); // Navigate to quests list
                    }}
                >
                    Close
                    <img src={CloseIcon} alt="CloseIcon" />
                </div>
                <img
                    className={styles.modal__block_image}
                    src={modalSuccessInfo.image || championImage}
                    alt="quests"
                />
                <div className={styles.modal__block_title}>
                    Quest is completed!
                </div>
                <div>
                    <div className={styles.modal__block_label}>
                        You got Rewards:
                    </div>
                    {modalSuccessInfo.rewards?.map((item, index) => (
                        <div key={index} className={styles.modal__block_reward}>
                            {item}
                        </div>
                    ))}
                    <a href="/quests" className={styles.modal__btn} >
                        Check your account
                    </a>
                </div>
            </div>
        </div>
    )
}