import { useQuery, gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      email
      name
      hpBalance
      leaderboardPosition
      questsCompleted
      activeReferrals {
        count
        levelUnlocked
      }
      avatarUrl
    }
  }
`;

export function useCurrentUser() {
  const {
    loading,
    error,
    data,
    refetch
  } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      if (error.graphQLErrors.some(err => err.extensions.code === "UNAUTHENTICATED")) {
        // Handle session expiration
        return;
      }
      console.error('Error fetching user info:', error);
    },
  });

  return {
    loading,
    error,
    currentUser: data?.getCurrentUser,
    refetch
  };
} 