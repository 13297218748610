import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Ensure useNavigate is imported
import { useQuery, useMutation, gql } from '@apollo/client';
import styles from "./Main.module.scss";
import Header from "../../../../components/Header/Header"
import JSON5 from 'json5';

import ArrowIcon from "../../../../img/arrow.svg";
import TelegramIcon from "../../../../img/telegram.svg";
import DiscordIcon from "../../../../img/discord.svg";
import CloseIcon from "../../../../img/close.svg";
import QuestLevel from "../QuestLevel/QuestLevel";
import ActionStep from "../ActionStep/ActionStep";
import WalletIcon from "../../../../img/wallet.svg";
import CupIcon from "../../../../img/cup_gold.svg";
import GemIcon from "../../../../img/gem.svg";
import CryptoMonkeysIcon from "../../../../img/cryptomonkeys.svg";
import ParticipantsIcon from "../../../../img/participants.svg";
import questTwitterIcon from "../../../../img/twitter.svg";
import InviteIcon from "../../../../img/invite.svg";
import InviteFriendsIcon from "../../../../img/inviteFriends.svg";
import CopyIcon from "../../../../img/copy.svg";
import CreateContentIcon from "../../../../img/content.svg";
import QuizIcon from "../../../../img/quiz.svg";

import questEmailIcon from "../../../../img/questEmail.svg";
import questQuestionIcon from "../../../../img/questQuestion.svg";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import ChampionIcon from "../../../../img/champion.svg";

import Champion1Icon from "../../../../img/champion1.svg";
import Champion2Icon from "../../../../img/champion2.svg";
import Champion3Icon from "../../../../img/champion3.svg";

import Team1Icon from "../../../../img/team1.svg";
import Team2Icon from "../../../../img/team2.svg";
import Team3Icon from "../../../../img/team3.svg";

import teamIcon from "../../../../img/team.svg";

import TaskActionMessage from "../TaskActionMessage/TaskActionMessage"; // Import the new component
import ModalSuccess from "./components/ModalSuccess/ModalSuccess";

// Define the GET_USER_PROGRESS query
const GET_USER_PROGRESS = gql`
  query GetUserProgress($campaignId: String!) {
    getUserProgress(campaignId: $campaignId) {
      id
      completedQuests {
        id
        quest {
          id
          title
          description
          pointsReward
        }
        completionTime
        pointsRewarded
      }
      completedTasks {
        id
        task {
          id
          title
          description
          type
        }
        completionTime
        pointsRewarded
      }
      completedQuestGroups {
        id
        questGroup {
          id
          title
          pointsReward
        }
        completionTime
        pointsRewarded
      }
      createdAt
      updatedAt
    }
  }
`;

// Update the ADD_COMPLETED_TASK mutation
const ADD_COMPLETED_TASK = gql`
  mutation AddCompletedTask($taskId: String!) {
    addCompletedTask(taskId: $taskId) {
      id
      task {
        id
        title
      }
      completionTime
      pointsRewarded
    }
  }
`;

export const GET_FORM_FIELD_IDS_BATCH = gql`
  query getFormFieldIdsBatch($taskIds: [ID!]!) {
    getFormFieldIdsBatch(taskIds: $taskIds) {
      taskId
      fieldIds
    }
  }
`;

const VERIFY_CONFIRMATION_CODE_MUTATION = gql`
  mutation VerifyConfirmationCode($code: String!) {
    verifyConfirmationCode(code: $code) {
      success
      message
      task {
        id
        title
        status
      }
    }
  }
`;

// Add this query near your other GraphQL queries
const GET_ALL_CAMPAIGNS = gql`
  query GetCampaigns {
    getCampaigns {
      id
      name
      description
      startTime
      endTime
    }
  }
`;

function Main() {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize navigate
  const [modalSuccessInfo, setModalSuccessInfo] = useState({
    isOpen: false,
    image: null,
    rewards: null,
  });

  const [heroQuestionStep, setHeroQuestionStep] = useState(1);
  const [stepsCompleteActivities, setStepsCompleteActivities] = useState([]);

  console.log("initializing quest")

  const formSubmitTaskIds = useMemo(() => {
    const dataForFormSubmitTasks = { ...location.state };
    console.log("dataForFormSubmitTasks", dataForFormSubmitTasks);
    const formSubmitTaskIds = dataForFormSubmitTasks.tasks
      .filter(task => task.type === 'form_submit')
      .map(task => task.id);
    console.log("formSubmitTaskIds", formSubmitTaskIds);
    return formSubmitTaskIds;
  }, []); // Load once on mount and do not re-compute on data changes

  const {
    loading: formFieldLoading,
    error: formFieldError,
    data: formFieldData,
  } = useQuery(GET_FORM_FIELD_IDS_BATCH, {
    variables: { taskIds: formSubmitTaskIds },
    skip: formSubmitTaskIds.length === 0, // Skip if there are no formField tasks
  });

  const quest = useMemo(() => {
    console.log("START get quests from location.state", location.state, formFieldLoading);
    const quest_data = { ...location.state };
    const newTasks = quest_data.tasks.flatMap(task => {
      // Start of Selection
      console.log("task", task, "extraData keys:", Object.keys(task.extraData));
      console.log("task.extraData.form", task.extraData["Form code"])
      const formFields = task.extraData["Form code"]

      if (task.type === 'form_submit' && task.extraData["Form code"]) {
        try {
          let isCompletedFieldIds = {};

          const formFieldsProcessed = JSON5.parse(formFields);
          task.extraData.form = formFieldsProcessed;
          // Start of Selection
          if (task.extraData.form.length > 0) {
            console.log("adding new task", task.extraData.form, formFieldData)

            // Check if formFieldData is defined and has the expected structure

            console.log("formFieldData", formFieldData)
            if (formFieldData && formFieldData.getFormFieldIdsBatch) {
              formFieldData.getFormFieldIdsBatch.forEach(({ taskId, fieldIds }) => {
                fieldIds.forEach((fieldId) => {
                  isCompletedFieldIds[fieldId] = true;
                  console.log("isCompletedFieldIds[fieldId]", fieldId, isCompletedFieldIds[fieldId])
                });
              });
            } else {
              console.warn("formFieldData is not available or does not have the expected structure.");
            }

            return task.extraData.form.map((formField, index) => ({
              __typename: 'Task',
              id: `${task.id}-${formField.name}-${index}`,
              title: formField.label,
              description: task.description,
              type: 'form_field_' + formField.type,
              status: task.status,
              extraData: { formField },
              createdAt: task.createdAt,
              updatedAt: task.updatedAt,
              position: task.position,
              isCompleted: isCompletedFieldIds[`${task.id}-${formField.name}-${index}`] || false,
            }));
          }
        } catch (error) {
          console.error(`Error parsing form code for task ID: ${task.id}`, error);
          // Handle the parsing error, e.g., return an empty array or a default value
          //return []; // Return an empty array or handle as needed
        }
      } else {
        console.log("no new task", task.extraData.form, Array.isArray(task.extraData.form))
      }
      return task;
    });

    //draft to add new form fields into the task
    quest_data.tasks = newTasks;

    console.log("quest_data", quest_data)
    return quest_data; // Access the entire quest object passed from QuestCard
  }, [location.state, formFieldData]);

  const [tasks, setTasks] = useState(quest.tasks || []);

  console.log("quest", quest);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    console.log("use effect  ... 155")
    if (modalSuccessInfo.isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [modalSuccessInfo.isOpen]);

  // Add this query to get campaigns
  const { data: campaignsData } = useQuery(GET_ALL_CAMPAIGNS);

  // Update your GET_USER_PROGRESS query to use the campaign ID from campaigns
  const {
    loading: progressLoading,
    error: progressError,
    data: progressData,
    refetch,
  } = useQuery(GET_USER_PROGRESS, {
    variables: {
      campaignId: campaignsData?.getCampaigns?.[0]?.id
    },
    skip: !campaignsData?.getCampaigns?.[0]?.id,
    fetchPolicy: 'network-only',
  });

  console.log('progressData', progressData);

  const taskIdToFieldIdsMap = useMemo(() => {
    const mapping = {};
    /*console.log("loading data into taskIdToFieldIdsMap", formFieldData)
    if (formFieldData?.getFormFieldIdsBatch) {
      console.log("Building taskIdToFieldIdsMap with new data");
    }
    const mapping = {};
    if (formFieldData && formFieldData.getFormFieldIdsBatch) {
      console.log("FormFieldData reloaded", formFieldData);
      formFieldData.getFormFieldIdsBatch.forEach(({ taskId, fieldIds }) => {
        console.log("Mapping taskIds to fieldIds", taskId, "fields", fieldIds);
        fieldIds.forEach((fieldId) => {
          console.log("Field ID inside of the app", fieldId);
          mapping[fieldId] = true;
        });
      });
    }*/
    return mapping;
  }, []);

  console.log("all tasks that are in form", taskIdToFieldIdsMap)

  useEffect(() => {
    console.log('formFieldData has changed:', formFieldData);
  }, [formFieldData]);

  // In Main.jsx
  const manuallyAdvanceToNextTask = () => {
    if (firstUncompletedTask) {
      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.id === firstUncompletedTask.id
            ? { ...task, isCompleted: true }
            : task
        )
      );
    }
  };

  // Function to check if all tasks are completed
  useEffect(() => {
    if (tasks.length > 0 && tasks.every(task => task.isCompleted)) {
      // All tasks are completed
      setModalSuccessInfo({
        isOpen: true,
        image: quest.image || '',
        rewards: [`${quest.pointsReward} HP`],
      });
    }
  }, [tasks, quest]);

  // Function to determine if a task is completed
  //pass here tsk type and id 
  const isTaskCompleted = useCallback((taskId, taskType) => {
    console.log("335 isTaskCompleted()", taskId, taskType)
    //if task tyee is form_field_submit then check if there is a field id in formFieldIds exits with value `${task.id}-${formField.name}-${index} 
    if (!progressData || !progressData.getUserProgress) {
      console.log("---no progress data")
      return false;
    }
    if (taskType === 'form_field_text' || taskType === 'form_field_email' || taskType === 'form_field_number' || taskType === 'form_field_textarea' || taskType === 'form_field_select' || taskType === 'form_field_radio' || taskType === 'form_field_checkbox') {
      //find in task.isCompleted if there is true
      const task = quest.tasks.find(task => task.id === taskId);
      console.log("---task", task)
      if (task.isCompleted) {
        console.log("---form field text is completed === true", taskId, taskIdToFieldIdsMap[taskId]);
        console.log("---taskIdToFieldIdsMap[taskId]", taskIdToFieldIdsMap[taskId])
        return true;
      }
      return false;
    }

    const isCompleted = progressData.getUserProgress?.completedTasks?.some(
      (completedTask) => completedTask?.task?.id === taskId
    ) || false;
    console.log("---isCompleted", isCompleted)
    return isCompleted;
  }, [progressData, taskIdToFieldIdsMap]);

  const firstUncompletedTask = useMemo(() => {
    console.log("394 firstUncompletedTask")
    return tasks.find(task => !isTaskCompleted(task.id, task.type));
  }, [tasks, progressData]);

  console.log("firstUncompletedTask: ", firstUncompletedTask);

  useEffect(() => {
    if (quest && quest.tasks && progressData) {
      console.log("402 useEffect is task completed")
      const allTasksCompleted = quest.tasks.every((task) => isTaskCompleted(task.id, task.type));
      console.log("allTasksCompleted", allTasksCompleted)
      if (allTasksCompleted) {
        setModalSuccessInfo({
          isOpen: true,
          image: quest.image || 'default_image.png', // Set the image you want to display
          rewards: [`${quest.pointsReward} HP`], // Include quest point rewards
        });
      }
    }
  }, [progressData, quest, tasks, isTaskCompleted]);

  // Function to render quest component based on questType
  const renderQuestComponent = () => {
    return (
      <>
        <div className={styles.main__container}>
          <div
            className={styles.main__back_wrapper}
            onClick={() => navigate("/quests")}
          >
            <div className={styles.main__back}>
              <img src={ArrowIcon} alt="ArrowIcon" />
              Back
            </div>
          </div>
          <div className={styles.main__wrapper}>

            <div className={styles.main__info}>
              <div className={styles.main__info_header}>
                <div
                  className={`${styles.main__tg} wow animate__animated animate__tada`}
                >
                  <img src={TelegramIcon} alt="TelegramIcon" />
                </div>
                <div>
                  <div className={styles.main__info_gap}>
                    <QuestLevel level={quest.questGroupTitle} />
                  </div>
                  <div className={styles.main__info_title}>
                    {quest.questTitle}
                  </div>
                  <div className={styles.main__info_description}>
                    {quest.description}
                  </div>
                </div>
              </div>
              <div className={styles.main__info_step_title}>
                Finish all steps
              </div>
              <div className={styles.main__info_list}>
                {quest.tasks
                  ?.sort((a, b) => a.position - b.position)
                  .map((item) => {
                    let completed = false;
                    if (item.type === 'form_field_text' || item.type === 'form_field_email' || item.type === 'form_field_number' || item.type === 'form_field_textarea' || item.type === 'form_field_select' || item.type === 'form_field_radio' || item.type === 'form_field_checkbox') {
                      completed = item.isCompleted;
                    } else {
                      console.log("458", item.id, item.type)
                      completed = isTaskCompleted(item.id, item.type);
                    }
                    return (
                      <ActionStep
                        key={item.id}
                        actionInfo={item}
                        isCompleted={completed} // Pass completion status
                      />
                    );
                  })}
              </div>
              <dir className={styles.main__rewards}>
                <div className={styles.main__rewards_wrapper}>
                  <img src={CupIcon} alt="CupIcon" />
                  <div>Rewards</div>
                </div>
                <div className={styles.main__rewards_wrapper}>
                  {quest.pointsReward}
                  <img src={GemIcon} alt="GemIcon" />
                  HP
                </div>
              </dir>
              <div className={styles.participants}>
                <div className={styles.participants__title}>Participants</div>
                <div className={styles.participants__wrapper}>
                  <img src={ParticipantsIcon} alt="ParticipantsIcon" />
                  <div className={styles.participants__amount}>
                    <span>0</span> / 0
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.main__action} >
              <div
                className={styles.main__action_close}
                onClick={() => {
                  navigate("/quests");
                }}
              >
                Close
                <img src={CloseIcon} alt="CloseIcon" />
              </div>

              {/* Display the first uncompleted task's type and description */}
              {firstUncompletedTask && (
                <TaskActionMessage
                  task={firstUncompletedTask}
                  scrollToTop={scrollToTop}
                  handleVerify={handleVerify}
                  quest={quest}
                />
              )}

              <div className={styles.main__action_footer}>
                <div className={styles.main__action_skip}>Skip</div>
                <div className={styles.main__action_verify} onClick={handleVerify}>Verify</div>
              </div>
            </div>
          </div>
        </div>
        {/* Uncomment if needed
        <div className={styles.main__container}>
          <div
            className={styles.main__back_wrapper}
            onClick={() => navigate("/quests")}
          >
            <div className={styles.main__back}>
              <img src={ArrowIcon} alt="ArrowIcon" />
              Back
            </div>
          </div>
          <div className={styles.main__wrapper}>
            <div className={styles.main__info}>
              <div className={styles.main__info_header}>
                <div>
                  <div className={styles.main__info_title}>
                    Quest Not Found
                  </div>
                  <div className={styles.main__info_description}>
                    The quest you're looking for doesn't exist.
                  </div>
                </div>
              </div>
              <div
                className={styles.main__action_btn}
                onClick={() => navigate("/quests")}
              >
                Return to Quests
              </div>
            </div>
          </div>
        </div>
        */}
      </>
    );
  };

  const [addCompletedTask] = useMutation(ADD_COMPLETED_TASK, {
    refetchQueries: [
      { query: GET_USER_PROGRESS, variables: { campaignId: campaignsData?.getCampaigns?.[0]?.id } }
    ],
  });

  const [verifyConfirmationCode] = useMutation(VERIFY_CONFIRMATION_CODE_MUTATION);

  const handleVerify = async (isCompleted) => {
    console.log("---handle verify");
    console.log('handleVerify - quest\n', quest);
    // if (isCompleted) {
    //   console.log("HANDLE VERIFY - isCompleted = true")
    //   const taskToUpdate = quest.tasks.find(task => task.id === firstUncompletedTask.id);
    //   if (taskToUpdate) {
    //     taskToUpdate.isCompleted = true;
    //   }
    //   manuallyAdvanceToNextTask();
    //   return;
    // }
    // if (!firstUncompletedTask) {
    //   console.error("No uncompleted task found");
    //   return;
    // }

    //COMPLETE THE TASK
    try {
      const result = await addCompletedTask({
        variables: {
          taskId: firstUncompletedTask.id // Using optional chaining for safety
        },
      });
      console.log("Task completed:", result.data.addCompletedTask);
    } catch (error) {
      console.error("Error completing task:", error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (code) {
      verifyConfirmationCode({ variables: { code } })
        .then((response) => {
          if (response.data.verifyConfirmationCode.success) {
            // Update the task state to completed
            // e.g., set the task as completed in your state management
            // Proceed to the next task or update UI as needed
          } else {
            // Handle the failure case, e.g., show an error message
          }
        })
        .catch((error) => {
          // Handle network or server errors
        });
    }
  }, [location.search, verifyConfirmationCode]);

  /*if (formFieldLoading) return <div>Loading form fields...</div>;*/
  if (formFieldError) return <div>Error loading form fields: {formFieldError.message}</div>;

  return (
    <div className={styles.main}>
      <Header />
      <div className="container">
        {renderQuestComponent()}

        {/* Modal for success info */}
        {modalSuccessInfo.isOpen && (
          <ModalSuccess modalSuccessInfo={modalSuccessInfo} setModalSuccessInfo={setModalSuccessInfo} />
        )}

        {/* Display Progress Data */}

        {progressError ? (
          <div>Error loading progress: {progressError.message}</div>
        ) : (
          progressData && (
            <div className={styles.progress__data}>
              <h3>Your Progress</h3>
              <p>
                Completed Quests:{' '}
                {progressData.getUserProgress.completedQuests.length || 0}
              </p>
              <p>
                Completed Tasks:{' '}
                {progressData.getUserProgress.completedTasks.length || 0}
              </p>
              <p>
                Completed Quest Groups:{' '}
                {progressData.getUserProgress.completedQuestGroups.length || 0}
              </p>
              {/* Add more details as needed */}
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default Main;
