import React from "react";

import styles from "./Promo.module.scss";
import Header from "../../../../components/Header/Header";

function Promo() {
  return (
    <div className={styles.promo}>
      <Header />
      <div className="container">
        <div className={styles.promo__title}>
          Waitlist Quest Challenge is Live!
        </div>
      </div>
    </div>
  );
}

export default Promo;
