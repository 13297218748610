import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import styles from "./QuestSection.module.scss";
import QuestCard from "../QuestCard/QuestCard";
import QuestLevel from "../QuestLevel/QuestLevel";
import combineClasses from "../../../../helpers/combineClasses";
import QuestFloatingBlock from "../QuestFloatingBlock/QuestFloatingBlock";

// Import your icons as needed
import questEmailIcon from "../../../../img/questEmail.svg";
import questTwitterIcon from "../../../../img/twitter.svg";
import questDiscordIcon from "../../../../img/discord.svg";
import questQuestionIcon from "../../../../img/question.svg";
import questWalletIcon from "../../../../img/wallet.svg";
import ChampionIcon from "../../../../img/champion.svg";
import TeamIcon from "../../../../img/team.svg";
import InviteIcon from "../../../../img/invite.svg";
import InviteFriendsIcon from "../../../../img/inviteFriends.svg";
import CreateContentIcon from "../../../../img/content.svg";
import QuizIcon from "../../../../img/quiz.svg";

// Add new Skeleton component
const QuestSkeleton = () => (
  <div className={styles.quest__wrapper}>
    {/* QuestFloatingBlock Skeleton */}
    <div className={styles.quest__floating_skeleton}>
      <div className={styles.floating_header}>
        <div className={styles.skeleton_title_large}></div>
        <div className={styles.skeleton_text}></div>
      </div>
      
      <div className={styles.floating_stats}>
        {[1, 2, 3].map((stat) => (
          <div key={stat} className={styles.stats_row}>
            <div className={styles.skeleton_label}></div>
            <div className={styles.skeleton_value}></div>
          </div>
        ))}
      </div>

      <div className={styles.skeleton_progress}></div>
      <div className={styles.skeleton_button}></div>
    </div>

    {/* Quest Groups Skeleton */}
    <div className={styles.quest__block}>
      {[1, 2, 3].map((group) => (
        <div key={group} className={styles.quest__info}>
          {/* Quest Group Title */}
          <div className={styles.skeleton_title}></div>
          
          {/* Quest Level */}
          <div className={styles.skeleton_level}>
            <div className={styles.skeleton_level_text}></div>
            <div className={styles.skeleton_points_small}></div>
          </div>
          
          {/* Quest Cards - now showing 4 per row */}
          <div className={styles.quest__row}>
            {[1, 2, 3, 4].map((card) => (
              <div key={card} className={styles.quest_card_skeleton}>
                <div className={styles.card_content}>
                  <div className={styles.skeleton_icon}></div>
                  <div className={styles.skeleton_card_title}></div>
                  <div className={styles.skeleton_progress_bar}></div>
                  <div className={styles.skeleton_points_row}>
                    <span>+0</span>
                    <div className={styles.skeleton_gem}></div>
                    <span>HP</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
);

function QuestSection() {
  const [isRegistered, setIsRegistered] = useState(false);
  
  const GET_ALL_CAMPAIGNS = gql`
    query GetCampaigns {
      getCampaigns {
        id
        name
        description
        startTime
        endTime
      }
    }
  `;

  // Keep existing campaign query
  const GET_CAMPAIGN_DATA = gql`
    query GetCampaign($id: ID!) {
      campaign(id: $id) {
        id
        name
        description
        startTime
        endTime
        questGroups {
          id
          title
          pointsReward
          unlocksAt
          position
          quests {
            id
            title
            description
            pointsReward
            createdAt
            updatedAt
            position
            tasks {
              id
              title
              description
              type
              status
              extraData
              createdAt
              updatedAt
              position
            }
          }
        }
      }
    }
  `;

  // Get all campaigns first
  const { loading: campaignsLoading, error: campaignsError, data: campaignsData } = useQuery(GET_ALL_CAMPAIGNS);
console.log("campaignsData\n", campaignsData);
  // Use multiple queries for campaign details
  const { loading: campaignDetailsLoading, error: campaignDetailsError, data: campaignDetailsData } = useQuery(GET_CAMPAIGN_DATA, {
    variables: { 
      id: campaignsData?.getCampaigns?.[0]?.id // Updated to use getCampaigns instead of campaigns
    },
    skip: !campaignsData?.getCampaigns?.[0]?.id,
  });
  
  if (campaignsLoading || campaignDetailsLoading) {
    return (
      <div className={styles.quest}>
        <div className="container">
          <QuestSkeleton />
        </div>
      </div>
    );
  }
  if (campaignsError) return <p>Error loading campaigns list: {campaignsError.message}</p>;
  if (campaignDetailsError) return <p>Error loading campaign details: {campaignDetailsError.message}</p>;
  
  if (!campaignDetailsData) {
    return (
      <div className={styles.quest}>
        <div className="container">
          <QuestSkeleton />
        </div>
      </div>
    );
  }

  const { campaign } = campaignDetailsData;

  console.log("campaign\n", campaign);

  // Calculate total number of quests
  const totalQuests = campaign.questGroups.reduce(
    (accumulator, group) => accumulator + group.quests.length,
    0
  );

  return (
    <div
      className={combineClasses(
        styles,
        "quest",
        !isRegistered ? "quest__registered" : ""
      )}
    >
      <div className="container">
        <div className={styles.quest__wrapper}>
          {/* Pass totalQuests as a prop */}
          <QuestFloatingBlock isRegistered={isRegistered} totalQuests={totalQuests} />
          <div className={styles.quest__block}>
            {campaign.questGroups.map((group, groupIndex) => (
              <div className={styles.quest__info} key={group.id}>
                <div className={styles.quest__title}>
                  {group.title}
                </div>
                <QuestLevel
                  status={groupIndex === 0 ? "unlocked" : "locked"}
                  unlocksAt={group.unlocksAt}
                  coins={(() => {
                    console.log(`Quests in group ${group.title}:`, group.quests);
                    return group.quests.reduce((sum, quest) => {
                      console.log(`Quest: ${quest.title}, Group unlocks at: ${group.unlocksAt}, Points: ${quest.pointsReward || 0}`);
                      return sum + (quest.pointsReward || 0);
                    }, 0);
                  })()}
                />
                <div className={styles.quest__row}>
                  {group.quests.map((quest, questIndex) => (
                    <QuestCard
                      key={quest.id}
                      item={{ ...quest, questGroupTitle: group.title }}
                      index={groupIndex * 100 + questIndex} // Ensure unique index
                      color={
                        groupIndex === 1
                          ? "purple"
                          : groupIndex === 2
                            ? "pink"
                            : undefined
                      }
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestSection;