// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionStep_action__nFm5B {
  border-radius: 4px;
  border: 1px solid #000;
  background: #310c67;
  box-shadow: 0px 4px 0px 0px #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
}
.ActionStep_action__title__bV6SC {
  color: #fff;
  font-size: 14px;
  line-height: 120%;
}
.ActionStep_action__number__ZZVsF {
  font-family: November;
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  border-radius: 4px;
  background: #270659;
}
.ActionStep_action__info__tCA4k {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.ActionStep_action__completed__llog3 {
  border: 1px solid #0f0;
}
.ActionStep_action__completed__llog3 .ActionStep_action__number__ZZVsF {
  background: #0f0;
}`, "",{"version":3,"sources":["webpack://./src/pages/CurrentQuest/component/ActionStep/ActionStep.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;AACF;AAAE;EACE,WAAA;EACA,eAAA;EACA,iBAAA;AAEJ;AAAE;EACE,qBAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,8BAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,sBAAA;AAEJ;AADI;EACE,gBAAA;AAGN","sourcesContent":[".action {\n  border-radius: 4px;\n  border: 1px solid #000;\n  background: #310c67;\n  box-shadow: 0px 4px 0px 0px #000;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 8px 12px;\n  &__title {\n    color: #fff;\n    font-size: 14px;\n    line-height: 120%;\n  }\n  &__number {\n    font-family: November;\n    width: 30px;\n    min-width: 30px;\n    height: 30px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #fff;\n    -webkit-text-stroke-width: 1px;\n    -webkit-text-stroke-color: #000;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 120%;\n    text-transform: uppercase;\n    border-radius: 4px;\n    background: #270659;\n  }\n  &__info {\n    display: flex;\n    align-items: center;\n    column-gap: 10px;\n  }\n  &__completed {\n    border: 1px solid #0f0;\n    .action__number {\n      background: #0f0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action": `ActionStep_action__nFm5B`,
	"action__title": `ActionStep_action__title__bV6SC`,
	"action__number": `ActionStep_action__number__ZZVsF`,
	"action__info": `ActionStep_action__info__tCA4k`,
	"action__completed": `ActionStep_action__completed__llog3`
};
export default ___CSS_LOADER_EXPORT___;
