// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestLevel_questLevel__lz3gs {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  column-gap: 4px;
  color: #97c6f0;
  font-size: 12px;
  font-weight: 600;
  border-radius: 16px;
  border: 1px solid #97c6f0;
}
.QuestLevel_questLevel_2__iBjli {
  border-color: #feca9f;
  color: #feca9f;
}
.QuestLevel_questLevel_3__p2Tnz {
  border-color: #f37d48;
  color: #f37d48;
}`, "",{"version":3,"sources":["webpack://./src/pages/CurrentQuest/component/QuestLevel/QuestLevel.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AACF;AAAE;EACE,qBAAA;EACA,cAAA;AAEJ;AAAE;EACE,qBAAA;EACA,cAAA;AAEJ","sourcesContent":[".questLevel {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  padding: 4px 8px;\n  column-gap: 4px;\n  color: #97c6f0;\n  font-size: 12px;\n  font-weight: 600;\n  border-radius: 16px;\n  border: 1px solid #97c6f0;\n  &_2 {\n    border-color: #feca9f;\n    color: #feca9f;\n  }\n  &_3 {\n    border-color: #f37d48;\n    color: #f37d48;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questLevel": `QuestLevel_questLevel__lz3gs`,
	"questLevel_2": `QuestLevel_questLevel_2__iBjli`,
	"questLevel_3": `QuestLevel_questLevel_3__p2Tnz`
};
export default ___CSS_LOADER_EXPORT___;
