// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestLevel_status__K849W {
  display: flex;
  align-items: center;
  column-gap: 7px;
}
.QuestLevel_status__wrapper__qtK\\+a {
  display: flex;
  align-items: center;
  column-gap: 2px;
}
.QuestLevel_status__unlocked__1uxio {
  color: #0f0;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
}
.QuestLevel_status__unlocked_text__xuVfv {
  color: #d9d9d9;
  font-size: 16px;
  line-height: 120%;
}
.QuestLevel_status__locked__mJgl- {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  display: flex;
  align-items: center;
  column-gap: 2px;
}`, "",{"version":3,"sources":["webpack://./src/pages/QuestPage/components/QuestLevel/QuestLevel.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAEJ;AAAE;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AADI;EACE,cAAA;EACA,eAAA;EACA,iBAAA;AAGN;AAAE;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAEJ","sourcesContent":[".status {\n  display: flex;\n  align-items: center;\n  column-gap: 7px;\n  &__wrapper {\n    display: flex;\n    align-items: center;\n    column-gap: 2px;\n  }\n  &__unlocked {\n    color: #0f0;\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 120%;\n    &_text {\n      color: #d9d9d9;\n      font-size: 16px;\n      line-height: 120%;\n    }\n  }\n  &__locked {\n    color: #fff;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 120%;\n    display: flex;\n    align-items: center;\n    column-gap: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `QuestLevel_status__K849W`,
	"status__wrapper": `QuestLevel_status__wrapper__qtK+a`,
	"status__unlocked": `QuestLevel_status__unlocked__1uxio`,
	"status__unlocked_text": `QuestLevel_status__unlocked_text__xuVfv`,
	"status__locked": `QuestLevel_status__locked__mJgl-`
};
export default ___CSS_LOADER_EXPORT___;
