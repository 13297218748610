import React, { useEffect, useRef, useState } from "react";
import styles from "./Header.module.scss";
import LogoIcon from "../../img/logo.svg";
import TelegramIcon from "../../img/telegram.svg";
import TwitterIcon from "../../img/twitter.svg";
import DiscordIcon from "../../img/discord.svg";
import ChevronIcon from "../../img/chevron.svg";
import BurgerIcon from "../../img/burger.svg";
import CloseIcon from "../../img/close.svg";
import AvatarIcon from "../../img/avatar.svg";
import combineClasses from "../../helpers/combineClasses";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SignUpModal from "../SignUpModal/SignUpModal"

import { useCurrentUser } from "../../hooks/useCurrentUser"

function Header() {
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [selectedLang, setSelectedLang] = useState("EN");
  const [isShowSignUp, setIsShowSignUp] = useState(false);

  const langWrapperRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { loading: userLoading, error: userError, currentUser, refetch } = useCurrentUser();

  const handleClickOutside = (event) => {
    if (
      langWrapperRef.current &&
      !langWrapperRef.current.contains(event.target)
    ) {
      setIsLangOpen(false);
    }
  };

  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
    setIsLangOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (isShowMenu || isShowSignUp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isShowMenu, isShowSignUp]);

  return (
    <div className={styles.header}>
      <div className="container">
        <div className={styles.header__wrapper}>
          <Link to={"/"}>
            <img
              className={styles.header__logo}
              style={{ width: "124px" }}
              src={LogoIcon}
              alt="logo"
            />
          </Link>
          <div className={styles.header__wrapper_container}>
            <div
              className={combineClasses(
                styles,
                "header__menu",
                isShowMenu ? "header__menu_active" : ""
              )}
            >
              <a href="/quests">Waitlist</a>
              <a href="/quests">Quests</a>
              <a href="/">Litepaper</a>
              <a href="/">Blog</a>
            </div>
            <div className={styles.header__socials}>
              <a href="https://t.me/heroguys_io" target="_blank">
                <img src={TelegramIcon} alt="TelegramIcon" />
              </a>
              <a href="https://twitter.com/heroguys_io" target="_blank">
                <img src={TwitterIcon} alt="TwitterIcon" />
              </a>
              <a href="https://discord.gg/heroguys" target="_blank">
                <img src={DiscordIcon} alt="DiscordIcon" />
              </a>
            </div>

            <div
              ref={langWrapperRef}
              className={combineClasses(
                styles,
                "header__lang",
                isLangOpen ? "header__lang_active" : ""
              )}
              onClick={() => setIsLangOpen(!isLangOpen)}
            >
              <div className={styles.header__lang_current}>
                {selectedLang}
              </div>
              <div className={combineClasses(
                styles,
                "header__lang_wrapper",
                isLangOpen ? "header__lang_wrapper_active" : ""
              )}>
                <div 
                  className={styles.header__lang_item}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLanguageChange("EN");
                  }}
                >
                  EN
                </div>
                <div 
                  className={styles.header__lang_item}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLanguageChange("RU");
                  }}
                >
                  RU
                </div>
              </div>
              <img 
                className={combineClasses(
                  styles,
                  "header__lang_chevron",
                  isLangOpen ? "header__lang_chevron_active" : ""
                )} 
                src={ChevronIcon} 
                alt="ChevronIcon" 
              />
            </div>
            {!userLoading && currentUser ? (
              <a href="/profile" className={styles.header__avatar}>
                <img src={AvatarIcon} alt="AvatarIcon" />
              </a>
            ) : (
              <button className={styles.header__avatar} onClick={() => setIsShowSignUp(true)}>
                <img src={AvatarIcon} alt="AvatarIcon" />
              </button>
            )}
            <button
              className={styles.burger}
              onClick={() => setIsShowMenu((prev) => !prev)}
            >
              {isShowMenu ? (
                <img src={CloseIcon} alt="CloseIcon" />
              ) : (
                <img src={BurgerIcon} alt="BurgerIcon" />
              )}
            </button>
            <div
              className={styles.header__btn_wrapper}
              onClick={() => setIsShowSignUp(true)}
            >
              <div className={styles.header__btn}>Play</div>
            </div>
          </div>
        </div>
      </div>
      {isShowSignUp && (
        <SignUpModal onClose={() => setIsShowSignUp(false)} />
      )}
    </div>
  );
}

export default Header;